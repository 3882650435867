import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const EditLeadModal = ({ lead, onClose, onSave, statusOptions, statusCssClasses }) => {
  const [editedLead, setEditedLead] = useState({ ...lead });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditedLead((prevLead) => ({
      ...prevLead,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSave = () => {
    onSave(editedLead);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4">
      {/* Modal Container */}
      <div className="bg-white rounded-lg shadow-md w-full max-w-2xl mx-auto flex flex-col max-h-[calc(100vh-50px)]">
        {/* Header */}
        <div className="p-4 border-b">
          <h2 className="text-xl text-center">Edit Lead</h2>
        </div>

        {/* Content */}
        <div className="p-4 overflow-y-auto flex-grow">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Status Field Spanning Two Columns */}
            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700">Status</label>
              <select
                name="LeadStatus"
                className={`mt-1 p-2 block w-full border rounded-md ${
                  statusCssClasses[editedLead.LeadStatus] || ''
                }`}
                value={editedLead.LeadStatus}
                onChange={handleChange}
                disabled={!editedLead.SalesRep}
              >
                {statusOptions.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    className={option.cssClass}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            {/* Company Information */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Company Name</label>
              <input
                type="text"
                name="LEGALNAME"
                className="mt-1 p-2 block w-full border rounded-md"
                value={editedLead.LEGALNAME || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">DBA Name</label>
              <input
                type="text"
                name="DBANAME"
                className="mt-1 p-2 block w-full border rounded-md"
                value={editedLead.DBANAME || ''}
                onChange={handleChange}
              />
            </div>

            {/* Contact Information */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Contact 1</label>
              <input
                type="text"
                name="CONTACT1"
                className="mt-1 p-2 block w-full border rounded-md"
                value={editedLead.CONTACT1 || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Contact 2</label>
              <input
                type="text"
                name="CONTACT2"
                className="mt-1 p-2 block w-full border rounded-md"
                value={editedLead.CONTACT2 || ''}
                onChange={handleChange}
              />
            </div>

            {/* Address Information */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Address</label>
              <input
                type="text"
                name="ADDRESS"
                className="mt-1 p-2 block w-full border rounded-md"
                value={editedLead.ADDRESS || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">City</label>
              <input
                type="text"
                name="CITY"
                className="mt-1 p-2 block w-full border rounded-md"
                value={editedLead.CITY || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">State</label>
              <input
                type="text"
                name="STATE"
                className="mt-1 p-2 block w-full border rounded-md"
                value={editedLead.STATE || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">ZIP</label>
              <input
                type="text"
                name="ZIP"
                className="mt-1 p-2 block w-full border rounded-md"
                value={editedLead.ZIP || ''}
                onChange={handleChange}
              />
            </div>

            {/* Contact Methods */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="EMAIL"
                className="mt-1 p-2 block w-full border rounded-md"
                value={editedLead.EMAIL || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Phone</label>
              <input
                type="text"
                name="PHONE"
                className="mt-1 p-2 block w-full border rounded-md"
                value={editedLead.PHONE || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Cell</label>
              <input
                type="text"
                name="CELL"
                className="mt-1 p-2 block w-full border rounded-md"
                value={editedLead.CELL || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Fax</label>
              <input
                type="text"
                name="FAX"
                className="mt-1 p-2 block w-full border rounded-md"
                value={editedLead.FAX || ''}
                onChange={handleChange}
              />
            </div>

            {/* Other Information */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Docket</label>
              <input
                type="text"
                name="DOCKET"
                className="mt-1 p-2 block w-full border rounded-md"
                value={editedLead.DOCKET || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">USDOT</label>
              <input
                type="text"
                name="USDOT"
                className="mt-1 p-2 block w-full border rounded-md"
                value={editedLead.USDOT || ''}
                onChange={handleChange}
              />
            </div>

            {/* Trucks and Inspection */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Trucks</label>
              <input
                type="number"
                name="TRUCKS"
                className="mt-1 p-2 block w-full border rounded-md"
                value={editedLead.TRUCKS || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Vehicle Inspection</label>
              <input
                type="text"
                name="VEHINSP"
                className="mt-1 p-2 block w-full border rounded-md"
                value={editedLead.VEHINSP || ''}
                onChange={handleChange}
              />
            </div>

            {/* Notes */}
            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700">Notes</label>
              <textarea
                name="Notes"
                className="mt-1 p-2 block w-full border rounded-md"
                value={editedLead.Notes || ''}
                onChange={handleChange}
              />
            </div>

           {/* Questionnaire Data */}
            <div className="md:col-span-2">
              <label className="text-sm font-medium text-gray-700 flex items-center">
                Questionnaire Data
                <button
                  className="ml-2 text-gray-400 hover:text-gray-600 cursor-not-allowed"
                  title="Edit Questionnaire"
                  disabled
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
              </label>
              <textarea
                name="Questionnaire"
                className="mt-1 p-2 block w-full border rounded-md bg-gray-100 text-gray-600 cursor-not-allowed"
                value={editedLead.Questionnaire || 'No questionnaire data available'}
                readOnly
              />
            </div>


            {/* Checkboxes Side by Side */}
            <div className="md:col-span-2 flex items-center space-x-6">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="OptOut"
                  checked={editedLead.OptOut || false}
                  onChange={handleChange}
                  className="mr-2"
                />
                <label className="text-sm font-medium text-gray-700">Opt Out</label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="AddedToMailingList"
                  checked={editedLead.AddedToMailingList || false}
                  onChange={handleChange}
                  className="mr-2"
                />
                <label className="text-sm font-medium text-gray-700">Added to Mailing List</label>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="p-4 border-t flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="bg-red-500 text-white p-2 rounded"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="themed-button"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditLeadModal;
