import React, { useEffect, useState } from 'react';
import Modal from './Modal'; // Assuming a reusable modal component exists
import Spinner from './Spinner';
import { useAuth } from './AuthContext';

const TransactionRejectsModal = ({ cardNumber, onClose }) => {
    const { currentUser } = useAuth();
    const [rejects, setRejects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchRejects = async () => {
            try {
                const response = await fetch(`${apiUrl}/dataServer`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        action: 'fetchTransactionRejects',
                        payload: { cardNum: cardNumber, userId: currentUser?.userId }
                    })
                });

                if (response.ok) {
                    const fetchedData = await response.json();
                    console.log(fetchedData);
                    setRejects(fetchedData.data || []);
                } else {
                    setError(`Server Error: ${await response.text()}`);
                }
            } catch (fetchError) {
                setError(`Error: ${fetchError.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchRejects();
    }, [cardNumber, currentUser]);

    return (
        <Modal isOpen={true} onClose={onClose} title="Rejections">
            <div className="px-6 py-4 text-gray-700">
                <p className="mb-4 text-sm"><strong>Card Number:</strong> {cardNumber}</p>
            </div>
            {loading ? (
                <div className="flex items-center justify-center">
                    <Spinner />
                </div>
            ) : error ? (
                <div className="text-red-500 text-center">{error}</div>
            ) : rejects.length === 0 ? (
                <div className="text-center text-gray-500">No transaction rejects found for this card.</div>
            ) : (
                <table className="table-auto w-full text-left">
                    <thead>
                        <tr>
                            <th className="px-4 py-2">Date</th>
                            <th className="px-4 py-2">Location</th>
                            <th className="px-4 py-2">City/State</th>
                            <th className="px-4 py-2">Error</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rejects.map((reject, index) => (
                            <tr key={index} className="border-t">
                                <td className="px-4 py-2">{new Date(reject.TranDate).toLocaleString()}</td>
                                <td className="px-4 py-2">{reject.LocName || 'N/A'}</td>
                                <td className="px-4 py-2">{`${reject.LocCity || 'N/A'}, ${reject.LocState || 'N/A'}`}</td>
                                <td className="px-4 py-2">{reject.ErrorDesc || 'N/A'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </Modal>
    );
};

export default TransactionRejectsModal;
