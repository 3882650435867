import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faTimes } from '@fortawesome/free-solid-svg-icons';

const FuelCardQuestionnaireModal = ({
  show,
  onClose,
  onSubmit,
  prefillEmail = '',
  prefillFleetSize = '',
}) => {
  // --- Sales Script Hidden by Default
  const [showScript, setShowScript] = useState(false);

  // --- The user’s answers
  const [answers, setAnswers] = useState({
    hasFuelCards: 'no',         // Q1: yes/no
    fuelCardType: '',           // Q1 if yes => single select
    canSendInfo: 'yes',         // Q2: yes/no
    bestEmail: prefillEmail,    // Q3: from props
    preferredFuelLocations: [], // Q4: multiple
    avgDiscount: '',            // Q5
    happyWithCurrentCards: 'yes', // Q6: yes/no
    monthlyVolume: '',          // Q7
    fleetSize: prefillFleetSize,// Q8: number
    areasRun: [],               // Q9: multiple
  });

  // --- Fuel card options (Q1)
  const fuelCardOptions = [
    'AtoB',
    'CFN',
    'Comdata Card',
    'ES Advantage',
    'England',
    'Fleet Card',
    'FuelMan Deep Saver',
    'GroundTeq',
    'Loves',
    'Mud Flap',
    'NASTC',    
    'P-Fleet',
    'Pilot/Flying J -Axle Fuel Card',
    'RTS Fuel Card',
    'Ramp',
    'Relay',
    'Schneider',
    'TCS',
    'Truckers Advantagae',
    'WEX',
    'WEX- Fleet One',
    'Other',
];

  // --- Preferred Fuel Locations (Q4) multiple
  const locationOptions = [
    'Independents/Other',
    'Pilot / Flying J',
    'Loves',
    'TA Petro',
    'Sapp Bros',
    'Wilco',
    'Speedway',
    'Quick Trip',
    'Kangaroo Pantry',
    'Petro Canada',
    'Imperial Oil',
    'Kwik Trip',
    'Quick Fuel',
    'Ambest',
    'CFN',
    'Quarels',
    'Maverik',
    'Road Ranger',
    'SC Fuels',
    'Circle K',
    'RaceTrac',
    "Casey's",
    "Doesn't Have Preference - Any",
  ];

  // --- Monthly Volume Options (Q7)
  const volumeOptions = [
    'Less than 1000 gallons',
    '1k - 5k gallons',
    '5k - 10k',
    'More than 10k',
  ];

  // --- Areas Run (Q9) multiple
  const areaOptions = [
    'Northeast',
    'Southeast',
    'Midwest',
    'Southwest',
    'West',
    'Pacific Northwest',
    'Canada',
    'OTR',
  ];

  // --- Toggle multiple choice items in array
  const handleMultiSelect = (field, value) => {
    setAnswers((prev) => {
      const currentValues = prev[field] || [];
      // If it's already selected, remove it; otherwise add it
      if (currentValues.includes(value)) {
        return {
          ...prev,
          [field]: currentValues.filter((item) => item !== value),
        };
      } else {
        return {
          ...prev,
          [field]: [...currentValues, value],
        };
      }
    });
  };

  // --- Generic handleChange
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const finalValue = type === 'checkbox' ? checked : value;
    setAnswers((prev) => ({ ...prev, [name]: finalValue }));
  };

  // --- Save (Submit) logic
  const handleSave = () => {
    // For multi-select fields, we'll store them as a semicolon-delimited string
    const combinedAnswers = {
      ...answers,
      preferredFuelLocations: answers.preferredFuelLocations.join(';'),
      areasRun: answers.areasRun.join(';'),
    };

    // Pass data up
    onSubmit(combinedAnswers);
  };

  if (!show) return null;

  // --- Simple, optional sales script
  const salesScript = `
FuelLink Sales Script:

**Introduction**
Hi, my name is [Your Name] from FuelLink, do you have a few minutes to chat? I wanted to take a moment to give you some information regarding our fuel program and how we help fleets like yours save on fuel costs.

**Start with Benefits**
Let me quickly highlight some of what makes FuelLink a better option:

1. **Exclusive Discounts**: 
   On average, our customers save $0.42 per gallon, with discounts available at over 4,500 fueling locations nationwide.

2. **Custom-Branded Portal**: 
   You’ll get access to a personalized fuel management portal. It’s branded with your company’s logo and colors, providing a more professional feel for your business. and it helps you track transactions, monitor payments, generate reports, and more—all in one place.
   - !Important to note: You can invite your users to the portal to find locations and/or manage card controls and all aspects of the account.
   - !Important to note: Fraud prevention and fraud protection through IBM and EFS partnership. Each transaction will generate an email notification through FuelLink.

3. **Fuel Location Finder**: 
   Our tools help you find the best discounts available before you fuel up. This way, you’re maximizing savings every time you stop at the pump.

4. **Extensive Network**: 
   FuelLink runs on the EFS network, which is the largest and most widely accepted fuel card network in the country. That means you’ll have access to all major truck stops and thousands of independent fueling locations.

5. **Discount Sharing** (if they don't share discounts with their drivers):
   FuelLink gives you the ability to share discount information with your drivers.

**Engage with the Customer**
Does that sound like something that could be helpful for your fleet?

**Handle Objections**

- **Objection: Pre-Pay Accounts**
  "I completely understand—pre-paying can feel like a change, but it’s actually what allows us to offer much deeper discounts. If pre-pay is a dealbreaker for you, we also have options through networks that provide credit lines. While the discounts may not be as high, we can work with you to find a solution that fits your needs. A credit application would be required for those accounts. Would you like me to provide details?"

- **Objection: I already have a fuel card**
  "That’s great! Many of our customers started with other programs before switching to FuelLink. Would you be open to letting us compare your current program? We can typically beat competitors 90% of the time. If we can't, we’ll let you know—no strings attached."

- **Objection: Discounts aren’t clear/convincing**
  "We can take a look at your last 30 days of transactions to show you exactly how much you’d save with FuelLink. This comparison costs nothing and gives you a clear idea of the value we can provide."

- **Objection: My fleet is too small**
  "We work with fleets of all sizes, from a single truck to hundreds. Our discounts apply whether you’re purchasing 1,000 gallons a month or 100,000. Every dollar saved on fuel helps your bottom line."

- **Objection: I’m happy with my current provider**
  "That’s excellent! It’s always good to have a program that works for you. What’s one thing about your current provider you wish could be better? FuelLink might be able to match or exceed that experience."

- **Objection: I don’t have time to switch**
  "I completely understand—running a fleet is a busy job. The great thing about FuelLink is that the setup is fast and hassle-free. We handle everything, including card distribution and portal setup, so you can focus on your operations."

- **Objection: I don’t see the value in a portal**
  "Our portal is designed to make your life easier. From tracking every transaction to providing fraud protection alerts in real time, it saves you hours of manual work and helps you stay ahead of potential issues."

- **Objection: I don’t fuel at major truck stops**
  "That’s no problem. Our network includes thousands of independent fueling locations, ensuring you get discounts wherever you’re fueling. Can I check your preferred locations to confirm they’re covered?"

**Ask Questions to Personalize the Conversation** (fill out the questionnaire!)
- How are you currently managing fuel costs?
- Do you typically fuel at specific locations, or do you go with whatever’s nearby? 
- How large is your fleet, and what’s your monthly fuel volume?
- Are you happy with your current fuel card, or is there something you wish worked better?

**Closing the Pitch**
With everything I’ve mentioned—exclusive discounts, a custom-branded portal, and access to the largest fueling network—I’d love to help you save more on fuel.

Would you like to move forward with a quick transaction analysis or schedule a demo?
`;



  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4">
      {/* Modal Container */}
      <div className="bg-white rounded-lg shadow-md w-full max-w-2xl mx-auto flex flex-col max-h-[calc(100vh-50px)]">
        {/* Header */}
        <div className="p-4 border-b">
          <h2 className="text-xl text-center">Fuel Card Questionnaire</h2>
        </div>

        {/* Content */}
        <div className="p-4 overflow-y-auto flex-grow space-y-4">
          {/* 1. Do you have fuel cards? */}
          <div className="space-y-1">
            <label className="block text-sm font-medium text-gray-700">
              1. Do you have fuel cards currently?
            </label>
            <div className="flex space-x-4">
              <label className="inline-flex items-center space-x-2">
                <input
                  type="radio"
                  name="hasFuelCards"
                  value="yes"
                  checked={answers.hasFuelCards === 'yes'}
                  onChange={handleChange}
                />
                <span>Yes</span>
              </label>
              <label className="inline-flex items-center space-x-2">
                <input
                  type="radio"
                  name="hasFuelCards"
                  value="no"
                  checked={answers.hasFuelCards === 'no'}
                  onChange={handleChange}
                />
                <span>No</span>
              </label>
            </div>
          </div>

          {/* If "Yes", show the fuel card type dropdown */}
          {answers.hasFuelCards === 'yes' && (
            <div>
              <label className="block text-sm font-medium text-gray-700">
                What type of Fuel Card?
              </label>
              <select
                name="fuelCardType"
                className="mt-1 p-2 block w-full border rounded-md"
                value={answers.fuelCardType}
                onChange={handleChange}
              >
                <option value="">Select Fuel Card...</option>
                {fuelCardOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* 2. Can we send you more info about our card? */}
          <div className="space-y-1">
            <label className="block text-sm font-medium text-gray-700">
              2. Can we send you more info about our fuel card program via email?
            </label>
            <div className="flex space-x-4">
              <label className="inline-flex items-center space-x-2">
                <input
                  type="radio"
                  name="canSendInfo"
                  value="yes"
                  checked={answers.canSendInfo === 'yes'}
                  onChange={handleChange}
                />
                <span>Yes</span>
              </label>
              <label className="inline-flex items-center space-x-2">
                <input
                  type="radio"
                  name="canSendInfo"
                  value="no"
                  checked={answers.canSendInfo === 'no'}
                  onChange={handleChange}
                />
                <span>No</span>
              </label>
            </div>
          </div>

          {/* 3. Best Email */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              3. What is the best email for you?
            </label>
            <input
              type="email"
              name="bestEmail"
              className="mt-1 p-2 block w-full border rounded-md"
              value={answers.bestEmail}
              onChange={handleChange}
              placeholder="Enter email..."
            />
          </div>

          {/* 4. Preferred Fuel Locations (multiple) */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              4. What fuel locations do you prefer? (Select all that apply)
            </label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2 mt-1">
              {locationOptions.map((loc) => (
                <label key={loc} className="inline-flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={answers.preferredFuelLocations.includes(loc)}
                    onChange={() => handleMultiSelect('preferredFuelLocations', loc)}
                  />
                  <span>{loc}</span>
                </label>
              ))}
            </div>
          </div>

          {/* 5. Average discount */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              5. What would you say your average discount today is?
            </label>
            <input
              type="text"
              name="avgDiscount"
              className="mt-1 p-2 block w-full border rounded-md"
              value={answers.avgDiscount}
              onChange={handleChange}
              placeholder="e.g. $0.10 off per gallon"
            />
          </div>

          {/* 6. Are you happy with your fuel cards? */}
          <div className="space-y-1">
            <label className="block text-sm font-medium text-gray-700">
              6. Are you happy with your current fuel cards?
            </label>
            <div className="flex space-x-4">
              <label className="inline-flex items-center space-x-2">
                <input
                  type="radio"
                  name="happyWithCurrentCards"
                  value="yes"
                  checked={answers.happyWithCurrentCards === 'yes'}
                  onChange={handleChange}
                />
                <span>Yes</span>
              </label>
              <label className="inline-flex items-center space-x-2">
                <input
                  type="radio"
                  name="happyWithCurrentCards"
                  value="no"
                  checked={answers.happyWithCurrentCards === 'no'}
                  onChange={handleChange}
                />
                <span>No</span>
              </label>
            </div>
          </div>

          {/* 7. Monthly Volume */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              7. What is your monthly volume?
            </label>
            <select
              name="monthlyVolume"
              className="mt-1 p-2 block w-full border rounded-md"
              value={answers.monthlyVolume}
              onChange={handleChange}
            >
              <option value="">Select volume range...</option>
              {volumeOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          {/* 8. Fleet Size */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              8. What is your fleet size?
            </label>
            <input
              type="number"
              name="fleetSize"
              className="mt-1 p-2 block w-full border rounded-md"
              value={answers.fleetSize}
              onChange={handleChange}
              placeholder="Number of trucks..."
            />
          </div>

          {/* 9. What areas do you run? (multiple) */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              9. What areas do you run? (Select all that apply)
            </label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2 mt-1">
              {areaOptions.map((area) => (
                <label key={area} className="inline-flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={answers.areasRun.includes(area)}
                    onChange={() => handleMultiSelect('areasRun', area)}
                  />
                  <span>{area}</span>
                </label>
              ))}
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="p-4 border-t flex justify-end space-x-4 items-center">
          {/* Icon to show/hide the script */}
          <FontAwesomeIcon
            icon={faBook}
            title="Show/Hide Sales Script"
            className="text-blue-600 cursor-pointer"
            onClick={() => setShowScript(!showScript)}
          />

          <button
            onClick={onClose}
            className="bg-red-500 text-white p-2 rounded"
          >
            Close
          </button>
          <button
            onClick={handleSave}
            className="themed-button"
          >
            Submit
          </button>
        </div>

        {/* Script overlay or pop-up */}
        {showScript && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    {/* Modal Container */}
    <div className="bg-white border shadow-lg rounded-lg w-full max-w-[80%] max-h-[80%] flex flex-col">
      {/* Header */}
      <div className="p-4 border-b flex justify-between items-center">
        <h3 className="text-lg font-semibold">Sales Script</h3>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={() => setShowScript(false)}
          className="text-gray-600 cursor-pointer"
        />
      </div>

      {/* Scrollable Content */}
      <div className="p-4 overflow-y-auto flex-grow">
        <pre className="whitespace-pre-wrap text-lg">
          {salesScript}
        </pre>
      </div>

      {/* Footer */}
      <div className="p-4 border-t flex justify-end">
        <button
          onClick={() => setShowScript(false)}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition"
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}

      </div>
    </div>
  );
};

export default FuelCardQuestionnaireModal;
