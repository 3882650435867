import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEnvelope,
    faEdit,
    faTrash,
    faPlus,
    faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import Spinner from './Spinner';
import { useTheme } from './ThemeContext';
import { useAuth } from './AuthContext';
import SalesRepModal from './SalesRepModal';
import EditLeadModal from './EditLeadModal';
import { getLocalStorage, setLocalStorage } from './localStorageHelpers';
import PageHeader from './PageHeader';
import MobileHeader from './MobileHeader';
import SendCRMEmailModal from './SendCRMEmailModal';
import FuelCardQuestionnaireModal from './FuelCardQuestionnaireModal';
import { debounce } from 'lodash';

const CRM = () => {
    const [leads, setLeads] = useState([]);
    const [salesReps, setSalesReps] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [isConfirm, setIsConfirm] = useState(false);
    const [confirmCallback, setConfirmCallback] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const { theme } = useTheme();
    const { currentUser } = useAuth();
    const [showModal, setShowModal] = useState(false);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [selectedLead, setSelectedLead] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const [showAlertBanner, setShowAlertBanner] = useState(false);
    const [hoveredLead, setHoveredLead] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [selectedLeadForEmail, setSelectedLeadForEmail] = useState(null);
    const [showFuelModal, setShowFuelModal] = useState(false);
    const [selectedLeadForQuestionnaire, setSelectedLeadForQuestionnaire] = useState(null);
    const [tooltipContent, setTooltipContent] = useState(null); // Tooltip content
    const [tooltipStyle, setTooltipStyle] = useState({}); // Tooltip position

    // UPDATED: Add both min and max trucks to the filters
    const [filters, setFilters] = useState({
        status: getLocalStorage('crmStatusFilter') || [],
        salesRep: getLocalStorage('crmSalesRepFilter') || '',
        trucksMin: getLocalStorage('crmTrucksMinFilter') || '',
        trucksMax: getLocalStorage('crmTrucksMaxFilter') || '',
    });

    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchLeadsAndSalesReps = async () => {        
        try {
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'getLeads',
                    payload: { userId: currentUser.userId, subDomain: currentUser.subDomain },
                }),
            });

            const result = await response.json();
            console.log('Leads and SalesReps result:', result);
            if (response.ok && result.success) {
                setLeads(result.leads || []);
                setSalesReps(result.salesReps || []);
            } else {
                console.error('Error fetching leads and sales reps:', result.message);
                setAlertMessage(`There was an error loading the data. Refresh the page.`);
            }
        } catch (error) {
            console.error('Error occurred while fetching leads and sales reps:', error);
            setAlertMessage(`There was an error loading the data. Refresh the page.`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchLeadsAndSalesReps();
    }, [apiUrl, currentUser]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.status-dropdown')) {
                setStatusDropdownOpen(false);
            }
        };
        if (statusDropdownOpen) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
        return () => document.removeEventListener('click', handleClickOutside);
    }, [statusDropdownOpen]);

    const handleOpenQuestionnaire = (lead) => {
        setSelectedLeadForQuestionnaire(lead);
        setShowFuelModal(true);
    };

    const showConfirmModal = (message, callback) => {
        setAlertMessage(message);
        setIsConfirm(true);
        setConfirmCallback(() => callback);
    };

    const closeAlertModal = () => {
        setAlertMessage(null);
        setIsConfirm(false);
        setConfirmCallback(null);
        fetchLeadsAndSalesReps();
    };

    const AlertModal = ({ message, onClose, onConfirm, isConfirm }) => {
        return (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[100]">
                <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-auto overflow-hidden">
                    {/* Header */}
                    <div className="px-6 py-4 border-b">
                        <h2 className="text-xl font-semibold text-gray-800">
                            {isConfirm ? 'You Sure?' : 'Alert'}
                        </h2>
                    </div>

                    {/* Message */}
                    <div className="px-6 py-4">
                        <p className="text-gray-700">{message}</p>
                    </div>

                    {/* Buttons */}
                    <div className="px-6 py-4 border-t flex justify-end space-x-4">
                        <button className="themed-button bg-gray-500 text-white" onClick={onClose}>
                            {isConfirm ? 'Cancel' : 'Close'}
                        </button>
                        {isConfirm && (
                            <button className="themed-button" onClick={onConfirm}>
                                Confirm
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    // Assigning a sales rep
    const handleAssignRep = async (repId) => {
        setLoading(true);
        try {
            let response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'assignSalesRepToLead',
                    payload: {
                        userId: currentUser.userId,
                        leadId: selectedLead.Id,
                        repId: repId,
                    },
                }),
            });

            let result = await response.json();

            if (response.ok && result.success) {
                const assignedRep = salesReps.find((rep) => rep.Id === repId);
                setLeads((prevLeads) =>
                    prevLeads.map((lead) =>
                        lead.Id === selectedLead.Id
                            ? { ...lead, SalesRep: assignedRep, SalesPerson: assignedRep.Id }
                            : lead
                    )
                );
                setShowAssignModal(false);
                if (result.message !== 'Sales rep assigned successfully') {
                    setShowAlertBanner(result.message);
                }
            } else if (result.currentRep) {
                // Lead is already assigned; prompt for confirmation
                showConfirmModal(
                    `This lead is already assigned to ${result.currentRep.FirstName} ${result.currentRep.LastName}. Do you want to override?`,
                    async () => {
                        // User confirmed; proceed with override
                        setLoading(true);
                        try {
                            response = await fetch(`${apiUrl}/dataServer`, {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({
                                    action: 'assignSalesRepToLead',
                                    payload: {
                                        userId: currentUser.userId,
                                        leadId: selectedLead.Id,
                                        repId: repId,
                                        override: true,
                                    },
                                }),
                            });

                            result = await response.json();

                            if (response.ok && result.success) {
                                const assignedRep = salesReps.find((rep) => rep.Id === repId);
                                setLeads((prevLeads) =>
                                    prevLeads.map((lead) =>
                                        lead.Id === selectedLead.Id
                                            ? { ...lead, SalesRep: assignedRep, SalesPerson: assignedRep.Id }
                                            : lead
                                    )
                                );
                                setShowAssignModal(false);
                                closeAlertModal();
                            } else {
                                setAlertMessage(`Error: ${result.message}`);
                                setIsConfirm(false);
                            }
                        } catch (error) {
                            setAlertMessage(`Error: ${error.message}`);
                            setIsConfirm(false);
                        } finally {
                            setLoading(false);
                        }
                    }
                );
            } else {
                setAlertMessage(`Error: ${result.message}`);
            }
        } catch (error) {
            setAlertMessage(`Error: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    // Add a new sales rep
    const handleAddRep = async (salesRep) => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'addSalesRep',
                    payload: {
                        userId: currentUser.userId,
                        subDomain: currentUser.subDomain,
                        salesRep: salesRep,
                    },
                }),
            });

            const result = await response.json();
            if (response.ok && result.success) {
                setSalesReps([...salesReps, result.salesRep]);
            } else {
                console.error('Error adding sales rep:', result.message);
            }
        } catch (error) {
            console.error('Error adding sales rep:', error);
        } finally {
            setLoading(false);
        }
    };

    const mapLeadToUpdates = (lead) => {
        return {
            docket: lead.DOCKET,
            usdot: lead.USDOT,
            legalName: lead.LEGALNAME,
            dbaName: lead.DBANAME,
            address: lead.ADDRESS,
            city: lead.CITY,
            state: lead.STATE,
            zip: lead.ZIP,
            phone: lead.PHONE,
            cell: lead.CELL,
            fax: lead.FAX,
            email: lead.EMAIL,
            contact1: lead.CONTACT1,
            contact2: lead.CONTACT2,
            trucks: lead.TRUCKS,
            vehInsp: lead.VEHINSP,
            salesPerson: lead.SalesPerson || null,
            notes: lead.Notes,
            optOut: lead.OptOut,
            addedToMailingList: lead.AddedToMailingList,
            leadStatus: lead.LeadStatus,
        };
    };

    const handleAssignClick = (lead) => {
        setSelectedLead(lead);
        setShowAssignModal(true);
    };

    // Change Lead Status
    const handleStatusChange = async (lead, newStatus) => {
        if (!lead.SalesRep) return;

        setLoading(true);
        try {
            const updatedLead = { ...lead, LeadStatus: newStatus };
            const updates = mapLeadToUpdates(updatedLead);

            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'updateLead',
                    payload: {
                        userId: currentUser.userId,
                        leadId: lead.Id,
                        updates: updates,
                    },
                }),
            });

            const result = await response.json();
            if (response.ok && result.success) {
                setLeads((prevLeads) =>
                    prevLeads.map((l) => (l.Id === lead.Id ? { ...l, LeadStatus: newStatus } : l))
                );
            } else {
                console.error('Error updating lead status:', result.message);
            }
        } catch (error) {
            console.error('Error updating lead status:', error);
        } finally {
            setLoading(false);
        }
    };

    // Edit Lead
    const handleEdit = (lead) => {
        setSelectedLead(lead);
        setShowEditModal(true);
    };

    // Save Lead
    const handleSaveLead = async (updatedLead) => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'updateLead',
                    payload: {
                        userId: currentUser.userId,
                        leadId: updatedLead.Id,
                        updates: {
                            leadStatus: updatedLead.LeadStatus,
                            docket: updatedLead.DOCKET,
                            usdot: updatedLead.USDOT,
                            dbaName: updatedLead.DBANAME,
                            address: updatedLead.ADDRESS,
                            city: updatedLead.CITY,
                            state: updatedLead.STATE,
                            zip: updatedLead.ZIP,
                            cell: updatedLead.CELL,
                            fax: updatedLead.FAX,
                            contact2: updatedLead.CONTACT2,
                            trucks: updatedLead.TRUCKS,
                            vehInsp: updatedLead.VEHINSP,
                            salesPerson: updatedLead.SalesPerson || null,
                            notes: updatedLead.Notes,
                            optOut: updatedLead.OptOut,
                            addedToMailingList: updatedLead.AddedToMailingList,
                            legalName: updatedLead.LEGALNAME,
                            contact1: updatedLead.CONTACT1,
                            email: updatedLead.EMAIL,
                            phone: updatedLead.PHONE,
                        },
                    },
                }),
            });

            const result = await response.json();
            if (response.ok && result.success) {
                // Update the lead in the state
                setLeads((prevLeads) =>
                    prevLeads.map((lead) => (lead.Id === updatedLead.Id ? { ...lead, ...updatedLead } : lead))
                );
                setAlertMessage('Lead Updated Successfully.');
                setShowEditModal(false);
            } else {
                console.error('Error updating lead:', result.message);
            }
        } catch (error) {
            console.error('Error updating lead:', error);
        } finally {
            setLoading(false);
        }
    };

    // Handle changes to filters; store them in localStorage
    const handleFilterChange = (filterKey, value) => {
        const newFilters = { ...filters, [filterKey]: value };
        setFilters(newFilters);
        // Save to local storage
        setLocalStorage(`crm${filterKey.charAt(0).toUpperCase() + filterKey.slice(1)}Filter`, value);
    };

    // Clear all filters at once
    const handleClearFilters = () => {
        const reset = {
            status: [],
            salesRep: '',
            trucksMin: '',
            trucksMax: '',
        };
        setFilters(reset);
        setLocalStorage('crmStatusFilter', []);
        setLocalStorage('crmSalesRepFilter', '');
        setLocalStorage('crmTrucksMinFilter', '');
        setLocalStorage('crmTrucksMaxFilter', '');
    };

    // Filtered data, including min/max trucks + search
    const filteredLeads = leads.filter((lead) => {
        // Filter by status
        if (filters.status.length > 0 && !filters.status.includes(lead.LeadStatus)) {
            return false;
        }
        // Filter by sales rep
        if (filters.salesRep) {
            if (!lead.SalesRep || lead.SalesRep.Id !== filters.salesRep) {
                return false;
            }
        }
        // Minimum trucks
        if (filters.trucksMin) {
            const leadTruckCount = parseInt(lead.TRUCKS || '0', 10);
            if (leadTruckCount < parseInt(filters.trucksMin, 10)) {
                return false;
            }
        }
        // Maximum trucks
        if (filters.trucksMax) {
            const leadTruckCount = parseInt(lead.TRUCKS || '0', 10);
            if (leadTruckCount > parseInt(filters.trucksMax, 10)) {
                return false;
            }
        }
        // Basic search across a few fields
        if (searchQuery.trim()) {
            const q = searchQuery.trim().toLowerCase();
            const fieldsToSearch = [
                lead.CONTACT1,
                lead.CONTACT2,
                lead.LEGALNAME,
                lead.EMAIL,
                lead.PHONE,
                lead?.SalesRep?.FirstName,
                lead?.SalesRep?.LastName,
                lead.LeadStatus,
            ]
                .filter(Boolean) // remove undefined
                .map((f) => f.toLowerCase());

            if (!fieldsToSearch.some((field) => field.includes(q))) {
                return false;
            }
        }

        return true;
    });

    // Status options and CSS classes
    const statusOptions = [
        { value: 'New', label: 'New', cssClass: 'status-new' },
        { value: 'Working', label: 'Working', cssClass: 'status-working' },
        { value: 'Contacted', label: 'Contacted', cssClass: 'status-contacted' },
        { value: 'Not Interested', label: 'Not Interested', cssClass: 'status-not-interested' },
        { value: 'Not Qualified', label: 'Not Qualified', cssClass: 'status-not-qualified' },
        { value: 'Qualified', label: 'Qualified', cssClass: 'status-qualified' },
    ];

    const statusCssClasses = {
        New: 'status-new',
        Working: 'status-working',
        Contacted: 'status-contacted',
        'Not Interested': 'status-not-interested',
        'Not Qualified': 'status-not-qualified',
        Qualified: 'status-qualified',
    };

    // const handleMouseEnter = (lead, event) => {
    //     setHoveredLead(lead);
    //     setTooltipPosition({ x: event.pageX, y: event.pageY });
    // };

    const handleMouseEnter = debounce((lead, event) => {
        setTooltipContent(
            <>
                <div>
                    <strong>Address:</strong> {lead.ADDRESS}, {lead.CITY}, {lead.STATE} {lead.ZIP}
                </div>
                <div>
                    <strong>Notes:</strong> {lead.Notes || 'No Notes'}
                </div>
                <div>
                    <strong>Questionnaire Data:</strong>
                    <ul className="mt-1 space-y-1">
                        {lead.Questionnaire
                            ? lead.Questionnaire.split('\n').map((line, index) => (
                                  <li key={index} className="text-sm">
                                      {line}
                                  </li>
                              ))
                            : 'No Data'}
                    </ul>
                </div>
            </>
        );
    
        const tooltipHeight = 150; // Approximate height of the tooltip
        const tooltipWidth = 200; // Approximate width of the tooltip
        const offsetY = window.innerHeight - event.pageY < tooltipHeight ? -tooltipHeight : 20;
        const offsetX = window.innerWidth - event.pageX < tooltipWidth ? -tooltipWidth : 20;
    
        setTooltipStyle({
            top: `${event.pageY + offsetY}px`,
            left: `${event.pageX + offsetX}px`,
        });
    }, 50);


    const handleMouseLeave = () => {
        setTooltipContent(null);
    };

    // Delete Lead
    const handleDelete = (id) => {
        showConfirmModal('Are you sure you want to delete this lead?', () => {
            deleteLead(id);
        });
    };

    const deleteLead = async (id) => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'deleteLead',
                    payload: {
                        userId: currentUser.userId,
                        leadId: id,
                    },
                }),
            });

            const result = await response.json();
            if (response.ok && result.success) {
                setLeads(leads.filter((lead) => lead.Id !== id));
                closeAlertModal();
                setAlertMessage('Lead deleted successfully.');
            } else {
                setAlertMessage(`Error deleting lead: ${result.message}`);
            }
        } catch (error) {
            setAlertMessage(`Error deleting lead: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    // Handle File
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleFileSubmit = async () => {
        if (!selectedFile) {
            alert('Please select a file.');
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await fetch(`${apiUrl}/addLeadsFromFile`, {
                method: 'POST',
                body: formData,
            });

            const result = await response.text();

            if (response.ok) {
                setAlertMessage('File uploaded and leads processed successfully.');
            } else {
                setAlertMessage(`Error: ${result.message}`);
            }
        } catch (error) {
            console.error('Error uploading file:', error.message);
            setAlertMessage('An error occurred while uploading the file.');
        } finally {
            setShowModal(false);
            setLoading(false);
        }
    };

    // Edit Sales Rep
    const handleEditRep = async (salesRep) => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'editSalesRep',
                    payload: {
                        userId: currentUser.userId,
                        salesRep: salesRep,
                    },
                }),
            });

            const result = await response.json();
            if (response.ok && result.success) {
                setSalesReps((prevSalesReps) =>
                    prevSalesReps.map((rep) => (rep.Id === salesRep.Id ? salesRep : rep))
                );
                setLeads((prevLeads) =>
                    prevLeads.map((lead) =>
                        lead.SalesRep && lead.SalesRep.Id === salesRep.Id
                            ? { ...lead, SalesRep: salesRep }
                            : lead
                    )
                );
            } else {
                console.error('Error editing sales rep:', result.message);
            }
        } catch (error) {
            console.error('Error editing sales rep:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitQuestionnaire = async (data) => {
        if (!selectedLeadForQuestionnaire) {
            console.error('No lead selected for questionnaire submission.');
            setAlertMessage(`No lead selected for questionnaire submission. Refresh the page and try again.`);
            setShowFuelModal(false); // Close the modal
            setSelectedLeadForQuestionnaire(null); // Clear the selected lead
            return;
        }

        setLoading(true);
    
        const payload = {
            action: 'saveQuestionnaire',
            payload: {
                userId: currentUser.userId, // Pass the current user's ID
                leadId: selectedLeadForQuestionnaire.Id, // Pass the lead's ID
                questionnaire: {
                    ...data, // Questionnaire answers from the form
                    bestEmail: data.bestEmail || selectedLeadForQuestionnaire.EMAIL || null,
                    fleetSize: data.fleetSize || selectedLeadForQuestionnaire.TRUCKS || null,
                },
            },
        };

        console.log('Payload: ', payload);
    
        try {
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });
    
            const result = await response.json();
    
            if (response.ok && result.success) {
                console.log('Questionnaire saved successfully:', result.message);
                setShowFuelModal(false); // Close the modal
                setAlertMessage(`Questionnaire saved successfully.`);
                setSelectedLeadForQuestionnaire(null); // Clear the selected lead                
            } else {
                console.error('Error saving questionnaire:', result.message);
            }
        } catch (error) {
            console.error('Error submitting questionnaire:', error.message);
        } finally {
            setLoading(false);
        }
    };
    

    // Delete Sales Rep
    const handleDeleteRep = async (repToDelete, replaceWith) => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'deleteSalesRep',
                    payload: {
                        userId: currentUser.userId,
                        salesRepId: repToDelete.Id,
                        replaceWith: replaceWith,
                    },
                }),
            });

            const result = await response.json();
            if (response.ok && result.success) {
                setSalesReps((prevSalesReps) => prevSalesReps.filter((rep) => rep.Id !== repToDelete.Id));
                setLeads((prevLeads) =>
                    prevLeads.map((lead) =>
                        lead.SalesRep && lead.SalesRep.Id === repToDelete.Id
                            ? {
                                ...lead,
                                SalesRep: replaceWith
                                    ? salesReps.find((rep) => rep.Id === replaceWith)
                                    : null,
                            }
                            : lead
                    )
                );
            } else {
                console.error('Error deleting sales rep:', result.message);
            }
        } catch (error) {
            console.error('Error deleting sales rep:', error);
        } finally {
            setLoading(false);
        }
    };

    // Send Email
    const handleEmail = (lead) => {
        setSelectedLeadForEmail(lead);
        setShowEmailModal(true);
    };

    // DataTable columns
    const columns = [
        {
            name: 'Contact',
            selector: (row) => row.CONTACT1 || row.CONTACT2 || '',
            sortable: true,
            cell: (row) => (
                <div
                    onMouseEnter={(e) => handleMouseEnter(row, e)}
                    onMouseLeave={handleMouseLeave}
                    className="relative"
                >
                    {row.CONTACT1 || row.CONTACT2 || ''}
                </div>
            ),
        },
        {
            name: 'Company Name',
            selector: (row) => row.LEGALNAME,
            sortable: true,
            width: '300px'
        },
        {
            // City, State column
            name: 'City, State',
            selector: (row) => `${row.CITY || ''}, ${row.STATE || ''}`,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Phone',
            selector: (row) => row.PHONE,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Email',
            selector: (row) => row.EMAIL,
            sortable: true,
        },
        {
            name: 'Trucks',
            selector: (row) => row.TRUCKS,
            sortable: true,
            width: '100px'
        },
        {
            name: 'Status',
            cell: (row) => {
                const isDisabled = !row.SalesRep;
                return (
                    <select
                        className={`p-1 rounded ${statusCssClasses[row.LeadStatus] || ''}`}
                        value={row.LeadStatus}
                        onChange={(e) => handleStatusChange(row, e.target.value)}
                        disabled={isDisabled}
                    >
                        {statusOptions.map((option) => (
                            <option key={option.value} value={option.value} className={option.cssClass}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                );
            },
            sortable: true,
        },
        {
            name: 'Assigned SalesRep',
            cell: (row) =>
                row.SalesRep ? (
                    <>
                        {row.SalesRep.FirstName} {row.SalesRep.LastName}
                        <FontAwesomeIcon
                            icon={faEdit}
                            className="ml-2 text-blue-500 cursor-pointer"
                            onClick={() => handleAssignClick(row)}
                        />
                    </>
                ) : (
                    <span onClick={() => handleAssignClick(row)} className="text-blue-500 cursor-pointer">
                        <FontAwesomeIcon icon={faPlus} /> Assign
                    </span>
                ),
        },
        {
            name: 'Actions',
            cell: (row) => {
                const hasSalesRep = !!row.SalesRep;

                return (
                    <div className="flex items-center space-x-3">
                        {/* Send Email Icon */}
                        <FontAwesomeIcon
                            icon={faEnvelope}
                            onClick={() => hasSalesRep && handleEmail(row)}
                            title="Send Email"
                            className={`cursor-pointer ${hasSalesRep
                                    ? 'text-gray-800 hover:text-blue-600'
                                    : 'opacity-50 cursor-not-allowed'
                                }`}
                        />

                        {/* Edit Lead Icon */}
                        <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => hasSalesRep && handleEdit(row)}
                            title="Edit Lead"
                            className={`cursor-pointer ${hasSalesRep
                                    ? 'text-gray-800 hover:text-blue-600'
                                    : 'opacity-50 cursor-not-allowed'
                                }`}
                        />

                        {/* NEW: Questionnaire Icon */}
                        <FontAwesomeIcon
                            icon={faQuestionCircle}
                            onClick={() => hasSalesRep && handleOpenQuestionnaire(row)}
                            title="Open Fuel Card Questionnaire"
                            className={`cursor-pointer ${hasSalesRep
                                ? 'text-gray-800 hover:text-blue-600'
                                : 'opacity-50 cursor-not-allowed'
                            }`}
                        />

                        {/* Delete Lead Icon */}
                        <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => handleDelete(row.Id)}
                            title="Delete Lead"
                            className="cursor-pointer text-gray-800 hover:text-red-600"
                        />
                    </div>
                );
            },
            ignoreRowClick: true,
        },
    ];

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: theme.primary,
                fontSize: '14px',
                fontWeight: 'bold',
                borderRadius: '8px 8px 0 0',
            },
        },
        headCells: {
            style: {
                color: theme.BtnTxtPrimaryColor, // Dark gray text color
            },
        },
    };
    

    return (
        <>

            <MobileHeader theme={theme} />
            <div className="mobile-container mx-auto px-4 sm:px-6 lg:px-8">
                <PageHeader heading="CRM" context="Manage Sales Leads" />

                {loading && <Spinner />}

                {/* Custom Alert Modal */}
                {alertMessage && (
                    <AlertModal
                        message={alertMessage}
                        onClose={closeAlertModal}
                        onConfirm={confirmCallback}
                        isConfirm={isConfirm}
                    />
                )}

                {/* Alert Banner */}
                {showAlertBanner && (
                    <div className="alert bg-green-200 text-green-600 p-4 rounded-md mb-4">
                        {showAlertBanner}
                        <button onClick={() => setShowAlertBanner(null)} className="float-right">
                            &times;
                        </button>
                    </div>
                )}

                {/* Filter Panel */}
                <div className="bg-white shadow-md rounded-lg p-6">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        {/* Status Filter */}
                        <div className="relative inline-block text-left status-dropdown">
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Filter by Status
                            </label>
                            <div>
                                <button
                                    type="button"
                                    className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                                    onClick={() => setStatusDropdownOpen(!statusDropdownOpen)}
                                >
                                    {filters.status.length > 0
                                        ? `Selected (${filters.status.length})`
                                        : 'All Statuses'}
                                    <svg
                                        className="-mr-1 ml-2 h-5 w-5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.292 7.707a1 1 0 011.415 0L10 10.999l3.293-3.292a1 1 0 111.415 1.414l-4 4.001a1 1 0 01-1.415 0l-4-4.001a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>

                            {statusDropdownOpen && (
                                <div className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                                    <div className="py-1">
                                        {statusOptions.map((option) => (
                                            <label
                                                key={option.value}
                                                className="flex items-center px-4 py-2 text-sm text-gray-700"
                                            >
                                                <input
                                                    type="checkbox"
                                                    value={option.value}
                                                    checked={filters.status.includes(option.value)}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        const isChecked = e.target.checked;
                                                        let newStatusFilter = [...filters.status];
                                                        if (isChecked) {
                                                            newStatusFilter.push(value);
                                                        } else {
                                                            newStatusFilter = newStatusFilter.filter(
                                                                (status) => status !== value
                                                            );
                                                        }
                                                        handleFilterChange('status', newStatusFilter);
                                                    }}
                                                    className="mr-2 text-blue-600 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded"
                                                />
                                                {option.label}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Sales Rep Filter */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Filter by Sales Rep
                            </label>
                            <select
                                className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
                                value={filters.salesRep}
                                onChange={(e) => handleFilterChange('salesRep', e.target.value)}
                            >
                                <option value="">All</option>
                                {salesReps.map((rep) => (
                                    <option key={rep.Id} value={rep.Id}>
                                        {rep.FirstName} {rep.LastName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Trucks (Min) Filter */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Filter by Trucks (Min)
                            </label>
                            <input
                                type="number"
                                min="0"
                                className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
                                value={filters.trucksMin}
                                onChange={(e) => handleFilterChange('trucksMin', e.target.value)}
                                placeholder="e.g. 5"
                            />
                        </div>

                        {/* Trucks (Max) Filter */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Filter by Trucks (Max)
                            </label>
                            <input
                                type="number"
                                min="0"
                                className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
                                value={filters.trucksMax}
                                onChange={(e) => handleFilterChange('trucksMax', e.target.value)}
                                placeholder="e.g. 50"
                            />
                        </div>

                        {/* Search Query */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Search
                            </label>
                            <input
                                type="text"
                                className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search leads..."
                            />
                        </div>
                    </div>

                    {/* Clear Filters Button */}
                    <div className="mt-4">
                        <button
                            onClick={handleClearFilters}
                            className="themed-button"
                        >
                            Clear All Filters
                        </button>
                    </div>
                </div>

                {/* Add New Leads Button */}
                <div className="my-4 flex justify-end">
                    <button className="themed-button" onClick={() => setShowModal(true)}>
                        Add New Leads
                    </button>
                </div>

                {/* The Actual Data Table */}
                <DataTable
                    columns={columns}
                    data={filteredLeads}
                    keyField="Id"
                    progressPending={loading}
                    pagination
                    highlightOnHover
                    pointerOnHover
                    noHeader
                    theme="default"
                    customStyles={customStyles}
                />

                {/* Upload Leads Modal */}
                {showModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                        <div className="bg-white p-8 rounded-lg shadow-lg w-96 transform transition-all duration-300 ease-out scale-100">
                            <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
                                Upload Leads File
                            </h2>

                            <p className="text-sm text-gray-600 mb-4 text-center">
                                Please select a CSV file containing leads to upload. Ensure the file
                                follows the correct format for successful import.
                            </p>

                            <div className="flex flex-col items-center justify-center mb-6">
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none focus:border-blue-500 p-2"
                                />
                                {selectedFile && (
                                    <p className="mt-2 text-sm text-green-600">
                                        Selected file:{' '}
                                        <span className="font-semibold">{selectedFile.name}</span>
                                    </p>
                                )}
                            </div>

                            <div className="flex justify-between">
                                <button
                                    className="themed-button transition-colors duration-300"
                                    onClick={handleFileSubmit}
                                >
                                    Submit
                                </button>
                                <button
                                    className="bg-red-500 text-white px-4 py-2 rounded-md shadow hover:bg-red-600 transition-colors duration-300"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Sales Rep Modal */}
                {showAssignModal && (
                    <SalesRepModal
                        show={showAssignModal}
                        onClose={() => setShowAssignModal(false)}
                        onAdd={handleAddRep}
                        onAssign={handleAssignRep}
                        currentUser={currentUser}
                        selectedUser={selectedLead}
                        salesReps={salesReps}
                        setSalesReps={setSalesReps}
                        onEdit={handleEditRep}
                        onDelete={handleDeleteRep}
                        setUsers={setLeads}
                    />
                )}

                {/* Edit Lead Modal */}
                {showEditModal && (
                    <EditLeadModal
                        lead={selectedLead}
                        onClose={() => setShowEditModal(false)}
                        onSave={handleSaveLead}
                        statusOptions={statusOptions}
                        statusCssClasses={statusCssClasses}
                    />
                )}

                {/* Email Modal */}
                {showEmailModal && (
                    <SendCRMEmailModal
                        show={showEmailModal}
                        onClose={() => setShowEmailModal(false)}
                        lead={selectedLeadForEmail}
                        salesReps={salesReps}
                        currentUser={currentUser}
                    />
                )}

                {showFuelModal && selectedLeadForQuestionnaire && (
                    <FuelCardQuestionnaireModal
                        show={showFuelModal}
                        onClose={() => setShowFuelModal(false)}
                        onSubmit={handleSubmitQuestionnaire}
                        // Pass in any prefill data you have:
                        prefillEmail={selectedLeadForQuestionnaire.EMAIL || ''}
                        prefillFleetSize={selectedLeadForQuestionnaire.TRUCKS || ''}
                    />
                )}

                {/* Tooltip with lead info */}
                {/* {hoveredLead && (
                    <div
                        className="absolute bg-gray-800 text-white p-3 rounded-lg shadow-lg max-w-xs"
                        style={{
                            top:
                                window.innerHeight - tooltipPosition.y < 150
                                    ? `${tooltipPosition.y - 150}px` // Display upwards if near the bottom
                                    : `${tooltipPosition.y + 20}px`, // Default to below the mouse
                            left:
                                window.innerWidth - tooltipPosition.x < 200
                                    ? `${tooltipPosition.x - 200}px` // Adjust left if near the right edge
                                    : `${tooltipPosition.x}px`, // Default to the mouse position
                        }}
                    >
                        <div>
                            <strong>Address:</strong> {hoveredLead.ADDRESS}, {hoveredLead.CITY}, {hoveredLead.STATE} {hoveredLead.ZIP}
                        </div>
                        <div>
                            <strong>Notes:</strong> {hoveredLead.Notes || 'No Notes'}
                        </div>
                        <div>
                            <strong>Questionnaire Data:</strong>
                            <ul className="mt-1 space-y-1">
                                {hoveredLead.Questionnaire
                                    ? hoveredLead.Questionnaire.split('\n').map((line, index) => (
                                        <li key={index} className="text-sm">
                                            {line}
                                        </li>
                                    ))
                                    : 'No Data'}
                            </ul>
                        </div>
                    </div>
                )} */}

                <div
                    className={`absolute bg-gray-800 text-white p-3 rounded-lg shadow-lg max-w-xs transition-opacity duration-200 ${tooltipContent ? 'opacity-100' : 'opacity-0 pointer-events-none'
                        }`}
                    style={tooltipStyle}
                >
                    {tooltipContent}
                </div>;


            </div>
        </>
    );
};

export default CRM;