import React, { useState, useEffect, useMemo } from 'react';
import { useTheme } from './ThemeContext';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import PageHeader from './PageHeader';
import MobileHeader from './MobileHeader';
import MainTableV2 from './MainTableV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Spinner from './Spinner';
import TransactionRejectModal from './TransactionRejectModal';

const CardSummaryUser = () => {
    const { theme } = useTheme();
    const { currentUser } = useAuth();
    const primaryColor = theme.primary;    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // Initially set to true
    const [alertMessage, setAlertMessage] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCard, setSelectedCard] = useState(null);

    const handleViewRejects = (cardData) => {
        setSelectedCard(cardData.CardNumber);
        // setSelectedCard('7083050030080407500');
    };

    const closeModal = () => {
        setSelectedCard(null);
    };
    
    const navigate = useNavigate();    
    const clearAlert = () => setAlertMessage(null);
    const apiUrl = process.env.REACT_APP_API_URL;

    const filterConfig = [
        { key: 'CardNumber', type: 'string' },
        { key: 'DriverName', type: 'string' },
        { key: 'DriverId', type: 'string' },
        { key: 'UnitNumber', type: 'string' },
        { key: 'Status', type: 'string' }
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiUrl}/dataServer`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        action: 'getUserCards',
                        payload: { userId: currentUser?.userId }
                    })
                });

                if (response.ok) {
                    const fetchedData = await response.json();
                    // console.log(fetchedData);
                    setData(fetchedData);
                } else {
                    setAlertMessage(`Server Error: ${await response.text()}`);
                }
            } catch (error) {
                setAlertMessage(`Error: ${error.message}`);
            } finally {
                setLoading(false); // Set loading to false after fetching data
            }
        };

        fetchData();
    }, [currentUser]);    

    const handleEditClick = (cardData) => {
        navigate('/editcard', { state: { cardNumber: cardData.CardNumber } });
    };

    const filteredData = useMemo(() => {
        if (searchQuery === '') return data;
        return data.filter((row) => {
            return Object.values(row).some((s) =>
                s && s.toString().toLowerCase().includes(searchQuery.toLowerCase())
            );
        });
    }, [searchQuery, data]);

    const columns = useMemo(() => [
        {
            name: 'Card Number',
            selector: row => row.CardNumber,
            sortable: true,
            reorder: true
        },
        {
            name: 'Driver Name',
            selector: row => row.DriverName || 'N/A', // Handling potential null values
            sortable: true,
            reorder: true
        },
        {
            name: 'Driver ID',
            selector: row => row.DriverId || 'N/A',
            sortable: true,
            reorder: true,
            hide: 'sm'
        },
        {
            name: 'Unit ID',
            selector: row => row.UnitNumber,
            sortable: true,
            reorder: true,
            hide: 'sm'
        },
        {
            name: 'Status',
            selector: row => row.Status,
            sortable: true,
            reorder: true,
            hide: 'sm'
        },
        {
            name: 'Actions',
            cell: row => (
                <div className="flex space-x-3">
                    <button
                        className="themed-button"
                        onClick={() => handleEditClick(row)}
                    >
                        Edit
                    </button>
                    <button
                        className="themed-button"
                        onClick={() => handleViewRejects(row)}
                    >
                        View Rejects
                    </button>                    
                </div>
            ),
            ignoreRowClick: true,
            width: '250px'
        }
    ], [handleViewRejects, handleEditClick]);

    return (
        <>
            <MobileHeader theme={theme} />
            <div className='mobile-container'>
                <PageHeader
                    heading="Manage Your Cards"
                    context="Active Cards, Assign Prompts, Manage Limits"
                />

                {loading ? (
                    <div className="flex items-center justify-center h-full">
                        <Spinner />
                    </div>
                ) : (
                    currentUser.HasOrderedCards && data.length === 0 ? (
                        <div className="flex items-center justify-center h-full ">
                            <div className="p-6 bg-blue-100 border-t-4 border-blue-500 rounded shadow-md text-blue-900 w-full mx-auto" role="alert">
                                <div className="flex items-center">
                                    <FontAwesomeIcon icon={faInfoCircle} className="text-blue-500 text-3xl mr-4" />
                                    <div>
                                        <p className="font-bold">Cards are being prepared</p>
                                        <p className="text-sm">Your order is being processed. Please check back shortly to see your new card(s).</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <MainTableV2
                            columns={columns}
                            data={filteredData}
                            primaryColor={primaryColor}
                            loading={loading}
                            alertMessage={alertMessage}
                            clearAlert={clearAlert}                            
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            filterConfig={filterConfig}
                        />
                    )
                )}
            </div>
            {selectedCard && (
                <TransactionRejectModal
                    cardNumber={selectedCard}
                    onClose={closeModal}
                />
            )}
        </>
    );
};

export default CardSummaryUser;


