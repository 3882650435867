import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useAuth } from './AuthContext';
import { format, parse } from 'date-fns';
import Spinner from './Spinner';

// -- Configuration for policies and contracts -- //
const policyDeductionConfig = [
  // e.g., policyNumber = 334 => Deduct 100% (1.0) of that policy's commission from profit
  { policyNumber: 334, deductionPercentage: 1.0 },
  // Add more policies or change deductionPercentage as needed
];

const contractProfitSplit = [
  // For contract 745687 (Fuellink), we take 50% (0.5) of the remaining profit after commissions
  { contractId: 745687, splitPercentage: 0.5 },
  // Add more contracts or adjust splitPercentage as needed
];

// -- Contract Mapping -- //
const contractMapping = [
  { contractId: 273898, description: 'GO TRANSPORT LLC' },
  { contractId: 392261, description: 'eCapital' },
  { contractId: 425680, description: 'C Fuel' },
  { contractId: 492191, description: 'BG' },
  { contractId: 684747, description: 'FBT' },
  { contractId: 684748, description: 'Fila Mar' },
  { contractId: 745687, description: 'Fuellink' },
  { contractId: 753698, description: 'AQHA' },
  { contractId: 793342, description: 'CANADIAN' },
];

// -- Utility Functions -- //
const formatMonthYear = (dateString) => {
  const date = parse(`${dateString}-01`, 'yyyy-MM-dd', new Date());
  return format(date, 'MMM-yyyy');
};

const getContractDescription = (contractId) => {
  const contract = contractMapping.find((item) => item.contractId === contractId);
  return contract ? contract.description : contractId;
};

const aggregateDataByMonth = (data) => {
  const aggregatedData = {};
  data.forEach((item) => {
    const key = item.month;
    if (!aggregatedData[key]) {
      aggregatedData[key] = {
        month: item.month,
        transactionCount: item.transactionCount,
        totalAmount: item.totalAmount,
      };
    } else {
      aggregatedData[key].transactionCount += item.transactionCount;
      aggregatedData[key].totalAmount += item.totalAmount;
    }
  });
  return Object.values(aggregatedData);
};

// -- Updated computeBillingData to handle commissions and profit-sharing -- //
function computeBillingData(usageData, subscriptionData, profitData, commissionData) {
  // Build quick lookups for profit & commission
  const profitMap = {};
  profitData.forEach((p) => {
    const key = `${p.Month}-${p.ContractId}`;
    profitMap[key] = p.TotalProfit;
  });

  const commissionMap = {};
  commissionData.forEach((c) => {
    const key = `${c.Month}-${c.policyNumber}`;
    commissionMap[key] = c.TotalCommission;
  });

  const billingDataMap = {};

  // Populate billingDataMap from usageData
  usageData.forEach((item) => {
    const { monthKey, month, contractId, transactionCount } = item;

    if (!billingDataMap[monthKey]) {
      billingDataMap[monthKey] = {
        month,
        totalTransactionCount: 0,
        transactionFees: 0.0,
        subscriptionFees: 0.0,
        billingAmount: 0.0,
        profitShare: 0.0, // We'll accumulate additional revenue from profit splits here
      };
    }

    // Exclude ContractId 273898 from transaction counts
    if (contractId !== 273898) {
      billingDataMap[monthKey].totalTransactionCount += transactionCount;
    }
  });

  // Add subscription fees per month
  subscriptionData.forEach((subscription) => {
    const { MonthlyAmount, StartDate, EndDate } = subscription;
    const start = new Date(StartDate);
    const end = EndDate ? new Date(EndDate) : new Date();

    let currentMonth = new Date(start.getFullYear(), start.getMonth(), 1);
    let endMonth = new Date(end.getFullYear(), end.getMonth(), 1);
    endMonth.setMonth(endMonth.getMonth() + 1); // move to the start of the following month

    while (currentMonth < endMonth) {
      const key = `${currentMonth.getFullYear()}-${String(
        currentMonth.getMonth() + 1
      ).padStart(2, '0')}`;

      if (!billingDataMap[key]) {
        billingDataMap[key] = {
          month: formatMonthYear(key),
          totalTransactionCount: 0,
          transactionFees: 0.0,
          subscriptionFees: 0.0,
          billingAmount: 0.0,
          profitShare: 0.0,
        };
      }

      billingDataMap[key].subscriptionFees += MonthlyAmount;
      currentMonth.setMonth(currentMonth.getMonth() + 1);
    }
  });

  // Calculate transaction fees
  Object.values(billingDataMap).forEach((item) => {
    const transactionCount = item.totalTransactionCount;
    let transactionFee = 0.0;
    if (transactionCount > 100) {
      transactionFee = Math.ceil((transactionCount - 100) / 100) * 50.0;
    }
    item.transactionFees = transactionFee;
  });

  // Process profit data to add profit-sharing
  profitData.forEach((p) => {
    const monthKey = p.Month;
    const contractId = p.ContractId;
    if (!billingDataMap[monthKey]) return;

    let remainingProfit = p.TotalProfit || 0.0;

    // Deduct relevant policy commissions
    policyDeductionConfig.forEach((policyCfg) => {
      const commissionKey = `${monthKey}-${policyCfg.policyNumber}`;
      if (commissionMap[commissionKey]) {
        // e.g. policy 334 => deduct 100% of that commission from the profit
        const commissionAmt = commissionMap[commissionKey];
        remainingProfit -= commissionAmt * policyCfg.deductionPercentage;
      }
    });

    // Check if the contract requires a profit split
    const splitCfg = contractProfitSplit.find((c) => c.contractId === contractId);
    if (splitCfg) {
      const share = remainingProfit * splitCfg.splitPercentage;
      billingDataMap[monthKey].profitShare += share;
    }
  });

  // Final billing amount = transactionFees + subscriptionFees + profitShare
  Object.values(billingDataMap).forEach((item) => {
    item.billingAmount = item.transactionFees + item.subscriptionFees + item.profitShare;
  });

  // Sort by YYYY-MM key
  return Object.keys(billingDataMap)
    .sort()
    .map((key) => billingDataMap[key]);
}

const UsageStats = () => {
  const { currentUser } = useAuth();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedContract, setSelectedContract] = useState('all');
  const [usageData, setUsageData] = useState([]);
  const [aggregatedChartData, setAggregatedChartData] = useState([]);
  const [billingData, setBillingData] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [profitData, setProfitData] = useState([]);
  const [commissionData, setCommissionData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser?.userId) return;

      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/dataServer`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            action: 'getMonthlyUsageData',
            payload: {
              userId: currentUser?.userId,
            },
          }),
        });

        if (response.ok) {
          const fetchedData = await response.json();
          console.log('Fetched data:', fetchedData);

          const { monthlyUsageData, subscriptionData, profitData, commissionData } = fetchedData;

          // Parse date filters
          const startDateObj = startDate ? parse(startDate, 'yyyy-MM-dd', new Date()) : null;
          const endDateObj = endDate ? parse(endDate, 'yyyy-MM-dd', new Date()) : null;

          // Filter usage data for display
          const filteredData = monthlyUsageData
            .filter((item) => {
              const itemDate = parse(`${item.Month}-01`, 'yyyy-MM-dd', new Date());
              return (
                (!startDateObj || itemDate >= startDateObj) &&
                (!endDateObj || itemDate <= endDateObj) &&
                (selectedContract === 'all' || item.ContractId === parseInt(selectedContract))
              );
            })
            .map((item) => {
              const monthKey = item.Month; // 'YYYY-MM'
              return {
                monthKey,
                month: formatMonthYear(monthKey),
                contract: getContractDescription(item.ContractId),
                contractId: item.ContractId,
                transactionCount: item.TransactionCount,
                totalAmount: item.TotalAmount,
              };
            });

          setCommissionData(commissionData);
          setProfitData(profitData);
          setUsageData(filteredData);
          setSubscriptionData(subscriptionData);
          setAggregatedChartData(aggregateDataByMonth(filteredData));

          // Prepare unfiltered usage data for billing computation
          const unfilteredUsageData = monthlyUsageData.map((item) => {
            const monthKey = item.Month; // 'YYYY-MM'
            return {
              monthKey,
              month: formatMonthYear(monthKey),
              contractId: item.ContractId,
              transactionCount: item.TransactionCount,
            };
          });

          // Compute billing data (now including commission & profit share)
          const computedBillingData = computeBillingData(
            unfilteredUsageData,
            subscriptionData,
            profitData,
            commissionData
          );
          setBillingData(computedBillingData);
        } else {
          setAlertMessage(`Server Error: ${await response.text()}`);
        }
      } catch (error) {
        setAlertMessage(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser?.userId, startDate, endDate, selectedContract, apiUrl]);

  return (
    <div className="p-6 bg-gray-100">
      <h2 className="text-2xl font-bold mb-4">Usage Statistics</h2>

      <div className="mb-6 flex gap-4 flex-wrap">
        {/* Date Filters */}
        <div className="flex items-center gap-2">
          <label className="font-semibold">Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="border p-2 rounded-lg"
          />
        </div>
        <div className="flex items-center gap-2">
          <label className="font-semibold">End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="border p-2 rounded-lg"
          />
        </div>

        {/* Contract Filter */}
        <div className="flex items-center gap-2">
          <label className="font-semibold">Contract:</label>
          <select
            value={selectedContract}
            onChange={(e) => setSelectedContract(e.target.value)}
            className="border p-2 rounded-lg"
          >
            <option value="all">All</option>
            {contractMapping.map((contract) => (
              <option key={contract.contractId} value={contract.contractId}>
                {contract.description}
              </option>
            ))}
          </select>
        </div>
      </div>

      {loading ? (
        <Spinner />
      ) : (
        <>
          {/* Monthly Usage Charts */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-6">
            {/* Line Chart */}
            <div className="bg-white p-4 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">Monthly Usage (Line Chart)</h3>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={aggregatedChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="totalAmount"
                    stroke="#8884d8"
                    name="Total Amount"
                    yAxisId={0}
                  />
                  <Line
                    type="monotone"
                    dataKey="transactionCount"
                    stroke="#82ca9d"
                    name="Transaction Count"
                    yAxisId={1}
                  />
                  <YAxis yAxisId={0} orientation="left" stroke="#8884d8" />
                  <YAxis yAxisId={1} orientation="right" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </div>

            {/* Bar Chart */}
            <div className="bg-white p-4 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">Monthly Usage (Bar Chart)</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={aggregatedChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
                  <Legend />
                  <Bar dataKey="totalAmount" fill="#8884d8" name="Total Amount" yAxisId={0} />
                  <Bar dataKey="transactionCount" fill="#82ca9d" name="Transaction Count" yAxisId={1} />
                  <YAxis yAxisId={0} orientation="left" stroke="#8884d8" />
                  <YAxis yAxisId={1} orientation="right" stroke="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Detailed Monthly Usage Table */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4">Detailed Monthly Usage</h3>
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Month
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Contract
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Transaction Count
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Total Amount (USD)
                  </th>
                </tr>
              </thead>
              <tbody>
                {usageData.map((item, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">{item.month}</td>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">{item.contract}</td>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                      {item.transactionCount}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                      ${item.totalAmount.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Monthly Billing Section */}
          <div className="bg-white p-4 rounded-lg shadow-md mt-6">
            <h3 className="text-xl font-semibold mb-4">Monthly Billing</h3>

            {/* Billing Chart */}
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={billingData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip
                  formatter={(value) => `$${new Intl.NumberFormat('en').format(value.toFixed(2))}`}
                />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="billingAmount"
                  stroke="#FF8042"
                  name="Total Billing Amount (Including Subscriptions/Profit Splits)"
                />
              </LineChart>
            </ResponsiveContainer>

            {/* Billing Table */}
            <table className="min-w-full bg-white mt-6">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Month
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Total Transaction Count (Excluding Contract 273898)
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Transaction Fees (USD)
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Subscription Fees (USD)
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Profit Share (USD)
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Billing Amount (USD)
                  </th>
                </tr>
              </thead>
              <tbody>
                {billingData.map((item, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">{item.month}</td>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                      {item.totalTransactionCount}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                      ${item.transactionFees.toFixed(2)}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                      ${item.subscriptionFees.toFixed(2)}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                      ${item.profitShare?.toFixed(2) || '0.00'}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                      ${item.billingAmount.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Profit Table */}
          <div className="bg-white p-4 rounded-lg shadow-md mt-6">
            <h3 className="text-xl font-semibold mb-4">Profit by Month</h3>
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Month
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Contract
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Total Profit (USD)
                  </th>
                </tr>
              </thead>
              <tbody>
                {profitData.map((item, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                      {formatMonthYear(item.Month)}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                      {getContractDescription(item.ContractId)}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                      ${item.TotalProfit.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Commission Table */}
          <div className="bg-white p-4 rounded-lg shadow-md mt-6">
            <h3 className="text-xl font-semibold mb-4">Commission by Policy</h3>
            <p className="text-sm text-gray-500 mb-4">Total amount owed to the partnership</p>
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Policy
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Month
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Total Commission (USD)
                  </th>
                </tr>
              </thead>
              <tbody>
                {commissionData.map((item, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                      {item.policyNumber}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                      {formatMonthYear(item.Month)}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                      ${item.TotalCommission.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Subscriptions Table */}
          <div className="bg-white p-4 rounded-lg shadow-md mt-6">
            <h3 className="text-xl font-semibold mb-4">Active Subscriptions</h3>
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Subscription Name
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Monthly Amount (USD)
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    Start Date
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
                    End Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {subscriptionData.map((subscription, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                      {subscription.SubscriptionName}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                      ${subscription.MonthlyAmount.toFixed(2)}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                      {format(new Date(subscription.StartDate), 'MMM dd, yyyy')}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                      {subscription.EndDate
                        ? format(new Date(subscription.EndDate), 'MMM dd, yyyy')
                        : 'Ongoing'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {alertMessage && <p className="text-red-500 mt-4">{alertMessage}</p>}
    </div>
  );
};

export default UsageStats;



// import React, { useState, useEffect } from 'react';
// import {
//   LineChart,
//   Line,
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from 'recharts';
// import { useAuth } from './AuthContext';
// import { format, parse } from 'date-fns';
// import Spinner from './Spinner';

// const contractMapping = [
//   { contractId: 273898, description: 'GO TRANSPORT LLC' },
//   { contractId: 392261, description: 'eCapital' },
//   { contractId: 425680, description: 'C Fuel' },
//   { contractId: 492191, description: 'BG' },
//   { contractId: 684747, description: 'FBT' },
//   { contractId: 684748, description: 'Fila Mar' },
//   { contractId: 745687, description: 'Fuellink' },
//   { contractId: 753698, description: 'AQHA' },
//   { contractId: 793342, description: 'CANADIAN' },
// ];

// const formatMonthYear = (dateString) => {
//   const date = parse(`${dateString}-01`, 'yyyy-MM-dd', new Date());
//   return format(date, 'MMM-yyyy');
// };

// const getContractDescription = (contractId) => {
//   const contract = contractMapping.find((item) => item.contractId === contractId);
//   return contract ? contract.description : contractId;
// };

// const aggregateDataByMonth = (data) => {
//   const aggregatedData = {};

//   data.forEach((item) => {
//     const key = item.month;
//     if (!aggregatedData[key]) {
//       aggregatedData[key] = {
//         month: item.month,
//         transactionCount: item.transactionCount,
//         totalAmount: item.totalAmount,
//       };
//     } else {
//       aggregatedData[key].transactionCount += item.transactionCount;
//       aggregatedData[key].totalAmount += item.totalAmount;
//     }
//   });

//   return Object.values(aggregatedData);
// };

// const computeBillingData = (usageData, subscriptionData) => {
//   const billingDataMap = {};

//   // Process unfiltered usage data to initialize billing data map
//   usageData.forEach((item) => {
//     const { monthKey, month, contractId, transactionCount } = item;

//     if (!billingDataMap[monthKey]) {
//       billingDataMap[monthKey] = {
//         month: month, // For display
//         totalTransactionCount: 0,
//         transactionFees: 0.0,
//         subscriptionFees: 0.0,
//         billingAmount: 0.0,
//       };
//     }

//     if (contractId !== 273898) {
//       billingDataMap[monthKey].totalTransactionCount += transactionCount;
//     }
//   });

//   // Process subscriptions
//   subscriptionData.forEach((subscription) => {
//     const { MonthlyAmount, StartDate, EndDate } = subscription;
//     const start = new Date(StartDate);
//     const end = EndDate ? new Date(EndDate) : new Date();

//     // Set currentMonth to the first day of the start month
//     let currentMonth = new Date(start.getFullYear(), start.getMonth(), 1);

//     // Adjust endMonth to the end of the subscription period
//     let endMonth = new Date(end.getFullYear(), end.getMonth(), 1);

//     // Include the end month in the loop
//     endMonth.setMonth(endMonth.getMonth() + 1);

//     while (currentMonth < endMonth) {
//       const monthKey = `${currentMonth.getFullYear()}-${String(
//         currentMonth.getMonth() + 1
//       ).padStart(2, '0')}`; // 'YYYY-MM'

//       // Ensure the month exists in the billingDataMap
//       if (!billingDataMap[monthKey]) {
//         billingDataMap[monthKey] = {
//           month: formatMonthYear(monthKey),
//           totalTransactionCount: 0,
//           transactionFees: 0.0,
//           subscriptionFees: 0.0,
//           billingAmount: 0.0,
//         };
//       }

//       // Add the subscription amount for the current month
//       billingDataMap[monthKey].subscriptionFees += MonthlyAmount;

//       // Move to the next month
//       currentMonth.setMonth(currentMonth.getMonth() + 1);
//     }
//   });

//   // Compute the total billing amount per month
//   Object.values(billingDataMap).forEach((item) => {
//     const transactionCount = item.totalTransactionCount;
//     let transactionFee = 0.0;
//     if (transactionCount > 100) {
//       transactionFee = Math.ceil((transactionCount - 100) / 100) * 50.0;
//     }
//     item.transactionFees = transactionFee; // Store transaction fees separately
//     item.billingAmount = item.transactionFees + item.subscriptionFees; // Sum up
//   });

//   // Sort the billing data by monthKey
//   const sortedBillingData = Object.keys(billingDataMap)
//     .sort()
//     .map((monthKey) => billingDataMap[monthKey]);

//   return sortedBillingData;
// };



// const UsageStats = () => {
//   const { currentUser } = useAuth();
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [selectedContract, setSelectedContract] = useState('all');
//   const [usageData, setUsageData] = useState([]);
//   const [aggregatedChartData, setAggregatedChartData] = useState([]);
//   const [billingData, setBillingData] = useState([]);
//   const [subscriptionData, setSubscriptionData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [alertMessage, setAlertMessage] = useState(null);
//   const [profitData, setProfitData] = useState([]);
//   const [commissionData, setCommissionData] = useState([]);
//   const apiUrl = process.env.REACT_APP_API_URL;

//   useEffect(() => {
//     const fetchData = async () => {
//       if (!currentUser?.userId) return;

//       setLoading(true);
//       try {
//         const response = await fetch(`${apiUrl}/dataServer`, {
//           method: 'POST',
//           headers: { 'Content-Type': 'application/json' },
//           body: JSON.stringify({
//             action: 'getMonthlyUsageData',
//             payload: {
//               userId: currentUser?.userId,
//             },
//           }),
//         });

//         if (response.ok) {
//           const fetchedData = await response.json();
//           console.log('Fetched data:', fetchedData);
//           const { monthlyUsageData, subscriptionData, profitData, commissionData } = fetchedData;

//           // Parse date filters
//           const startDateObj = startDate ? parse(startDate, 'yyyy-MM-dd', new Date()) : null;
//           const endDateObj = endDate ? parse(endDate, 'yyyy-MM-dd', new Date()) : null;

//           // Map and filter usage data for display (affected by filters)
//           const filteredData = monthlyUsageData
//             .filter((item) => {
//               const itemDate = parse(`${item.Month}-01`, 'yyyy-MM-dd', new Date());
//               return (
//                 (!startDateObj || itemDate >= startDateObj) &&
//                 (!endDateObj || itemDate <= endDateObj) &&
//                 (selectedContract === 'all' || item.ContractId === parseInt(selectedContract))
//               );
//             })
//             .map((item) => {
//               const monthKey = item.Month; // 'YYYY-MM'
//               return {
//                 monthKey: monthKey,
//                 month: formatMonthYear(monthKey), // e.g., 'Mar-2024'
//                 contract: getContractDescription(item.ContractId),
//                 contractId: item.ContractId,
//                 transactionCount: item.TransactionCount,
//                 totalAmount: item.TotalAmount,
//               };
//             });
           
//           setCommissionData(commissionData);
//           setProfitData(profitData);
//           setUsageData(filteredData);
//           setSubscriptionData(subscriptionData);
//           setAggregatedChartData(aggregateDataByMonth(filteredData));

//           // Prepare unfiltered usage data for billing computation
//           const unfilteredUsageData = monthlyUsageData.map((item) => {
//             const monthKey = item.Month; // 'YYYY-MM'
//             return {
//               monthKey: monthKey,
//               month: formatMonthYear(monthKey),
//               contractId: item.ContractId,
//               transactionCount: item.TransactionCount,
//             };
//           });

//           // Compute billing data using unfiltered usage data
//           const computedBillingData = computeBillingData(unfilteredUsageData, subscriptionData);
//           setBillingData(computedBillingData);
//         } else {
//           setAlertMessage(`Server Error: ${await response.text()}`);
//         }
//       } catch (error) {
//         setAlertMessage(`Error: ${error.message}`);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [currentUser?.userId, startDate, endDate, selectedContract]);

//   return (
//     <div className="p-6 bg-gray-100">
//       <h2 className="text-2xl font-bold mb-4">Usage Statistics</h2>
//       <div className="mb-6 flex gap-4 flex-wrap">
//         {/* Date Filters */}
//         <div className="flex items-center gap-2">
//           <label className="font-semibold">Start Date:</label>
//           <input
//             type="date"
//             value={startDate}
//             onChange={(e) => setStartDate(e.target.value)}
//             className="border p-2 rounded-lg"
//           />
//         </div>
//         <div className="flex items-center gap-2">
//           <label className="font-semibold">End Date:</label>
//           <input
//             type="date"
//             value={endDate}
//             onChange={(e) => setEndDate(e.target.value)}
//             className="border p-2 rounded-lg"
//           />
//         </div>
//         {/* Contract Filter */}
//         <div className="flex items-center gap-2">
//           <label className="font-semibold">Contract:</label>
//           <select
//             value={selectedContract}
//             onChange={(e) => setSelectedContract(e.target.value)}
//             className="border p-2 rounded-lg"
//           >
//             <option value="all">All</option>
//             {contractMapping.map((contract) => (
//               <option key={contract.contractId} value={contract.contractId}>
//                 {contract.description}
//               </option>
//             ))}
//           </select>
//         </div>
//       </div>

//       {loading ? (
//         <Spinner />
//       ) : (
//         <>
//           {/* Existing Usage Reporting */}
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-6">
//             {/* Line Chart */}
//             <div className="bg-white p-4 rounded-lg shadow-md">
//               <h3 className="text-xl font-semibold mb-4">Monthly Usage (Line Chart)</h3>
//               <ResponsiveContainer width="100%" height={300}>
//                 <LineChart data={aggregatedChartData}>
//                   <CartesianGrid strokeDasharray="3 3" />
//                   <XAxis dataKey="month" />
//                   <YAxis />
//                   <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
//                   <Legend />
//                   <Line
//                     type="monotone"
//                     dataKey="totalAmount"
//                     stroke="#8884d8"
//                     name="Total Amount"
//                     yAxisId={0}
//                   />
//                   <Line
//                     type="monotone"
//                     dataKey="transactionCount"
//                     stroke="#82ca9d"
//                     name="Transaction Count"
//                     yAxisId={1}
//                   />
//                   <YAxis yAxisId={0} orientation="left" stroke="#8884d8" />
//                   <YAxis yAxisId={1} orientation="right" stroke="#82ca9d" />
//                 </LineChart>
//               </ResponsiveContainer>
//             </div>

//             {/* Bar Chart */}
//             <div className="bg-white p-4 rounded-lg shadow-md">
//               <h3 className="text-xl font-semibold mb-4">Monthly Usage (Bar Chart)</h3>
//               <ResponsiveContainer width="100%" height={300}>
//                 <BarChart data={aggregatedChartData}>
//                   <CartesianGrid strokeDasharray="3 3" />
//                   <XAxis dataKey="month" />
//                   <YAxis />
//                   <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
//                   <Legend />
//                   <Bar dataKey="totalAmount" fill="#8884d8" name="Total Amount" yAxisId={0} />
//                   <Bar dataKey="transactionCount" fill="#82ca9d" name="Transaction Count" yAxisId={1} />
//                   <YAxis yAxisId={0} orientation="left" stroke="#8884d8" />
//                   <YAxis yAxisId={1} orientation="right" stroke="#82ca9d" />
//                 </BarChart>
//               </ResponsiveContainer>
//             </div>
//           </div>

//           {/* Data Table */}
//           <div className="bg-white p-4 rounded-lg shadow-md">
//             <h3 className="text-xl font-semibold mb-4">Detailed Monthly Usage</h3>
//             <table className="min-w-full bg-white">
//               <thead>
//                 <tr>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     Month
//                   </th>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     Contract
//                   </th>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     Transaction Count
//                   </th>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     Total Amount (USD)
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {usageData.map((item, index) => (
//                   <tr key={index}>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">{item.month}</td>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">{item.contract}</td>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">
//                       {item.transactionCount}
//                     </td>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">
//                       ${item.totalAmount.toFixed(2)}
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>

//           {/* New Billing Section */}
//           <div className="bg-white p-4 rounded-lg shadow-md mt-6">
//             <h3 className="text-xl font-semibold mb-4">Monthly Billing</h3>

//             {/* Billing Chart */}
//             <ResponsiveContainer width="100%" height={300}>
//               <LineChart data={billingData}>
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis dataKey="month" />
//                 <YAxis />
//                 <Tooltip
//                   formatter={(value) =>
//                     `$${new Intl.NumberFormat('en').format(value.toFixed(2))}`
//                   }
//                 />
//                 <Legend />
//                 <Line
//                   type="monotone"
//                   dataKey="billingAmount"
//                   stroke="#FF8042"
//                   name="Total Billing Amount (Including Subscriptions)"
//                 />
//               </LineChart>
//             </ResponsiveContainer>

//             {/* Billing Table */}
//             <table className="min-w-full bg-white mt-6">
//               <thead>
//                 <tr>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     Month
//                   </th>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     Total Transaction Count (Excluding Contract 273898)
//                   </th>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     Transaction Fees (USD)
//                   </th>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     Subscription Fees (USD)
//                   </th>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     Billing Amount (USD)
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {billingData.map((item, index) => (
//                   <tr key={index}>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">{item.month}</td>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">
//                       {item.totalTransactionCount}
//                     </td>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">
//                       ${item.transactionFees.toFixed(2)}
//                     </td>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">
//                       ${item.subscriptionFees.toFixed(2)}
//                     </td>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">
//                       ${item.billingAmount.toFixed(2)}
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>

//           </div>

//           {/* Profit Table */}
//           <div className="bg-white p-4 rounded-lg shadow-md mt-6">
//             <h3 className="text-xl font-semibold mb-4">Profit by Month</h3>
//             <table className="min-w-full bg-white">
//               <thead>
//                 <tr>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     Month
//                   </th>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     Contract
//                   </th>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     Total Profit (USD)
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {profitData.map((item, index) => (
//                   <tr key={index}>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">
//                       {formatMonthYear(item.Month)}
//                     </td>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">
//                       {getContractDescription(item.ContractId)}
//                     </td>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">
//                       ${item.TotalProfit.toFixed(2)}
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>

//           {/* Commission Table */}
//           <div className="bg-white p-4 rounded-lg shadow-md mt-6">
//             <h3 className="text-xl font-semibold mb-4">Commission by Policy</h3>
//             <table className="min-w-full bg-white">
//               <thead>
//                 <tr>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     Policy
//                   </th>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     Month
//                   </th>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     Total Commission (USD)
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {commissionData.map((item, index) => (
//                   <tr key={index}>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">
//                       {item.policyNumber}
//                     </td>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">
//                       {formatMonthYear(item.Month)}
//                     </td>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">
//                       ${item.TotalCommission.toFixed(2)}
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>


//           {/* New Subscriptions Table */}
//           <div className="bg-white p-4 rounded-lg shadow-md mt-6">
//             <h3 className="text-xl font-semibold mb-4">Active Subscriptions</h3>
//             <table className="min-w-full bg-white">
//               <thead>
//                 <tr>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     Subscription Name
//                   </th>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     Monthly Amount (USD)
//                   </th>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     Start Date
//                   </th>
//                   <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold">
//                     End Date
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {subscriptionData.map((subscription, index) => (
//                   <tr key={index}>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">
//                       {subscription.SubscriptionName}
//                     </td>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">
//                       ${subscription.MonthlyAmount.toFixed(2)}
//                     </td>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">
//                       {format(new Date(subscription.StartDate), 'MMM dd, yyyy')}
//                     </td>
//                     <td className="py-2 px-4 border-b border-gray-200 text-sm">
//                       {subscription.EndDate
//                         ? format(new Date(subscription.EndDate), 'MMM dd, yyyy')
//                         : 'Ongoing'}
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </>
//       )}

//       {alertMessage && <p className="text-red-500 mt-4">{alertMessage}</p>}
//     </div>
//   );
// };

// export default UsageStats;