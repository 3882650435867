import React, { useState, useEffect, useMemo } from 'react'; 
import { useTheme } from './ThemeContext';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import PageHeader from './PageHeader';
import MobileHeader from './MobileHeader';
import MainTableV2 from './MainTableV2';
import { useSelector } from 'react-redux';
import TransactionRejectModal from './TransactionRejectModal';

const CardSummaryTable = () => {
  const { theme } = useTheme();
  const { currentUser } = useAuth();
  const primaryColor = theme.primary;  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const clearAlert = () => setAlertMessage(null);
  const [statusFilter, setStatusFilter] = useState('');
  const [policyFilter, setPolicyFilter] = useState('');
  const policies = useSelector((state) => state.policies.policies);
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://efs-getpolicy.azurewebsites.net/api/FuelLink-LoadCardDataIntoTable?', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId: currentUser?.userId })
        });

        if (response.ok) {
          const fetchedData = await response.json();
          setData(fetchedData);
        } else {
          setAlertMessage(`Server Error: ${await response.text()}`);
        }
      } catch (error) {
        setAlertMessage(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser]);

  const handleEditClick = (cardData) => {
    navigate('/editcard', { state: { cardNumber: cardData.CardNumber } });
  };

  const handleViewRejects = (cardData) => {
    setSelectedCard(cardData.CardNumber);
    // setSelectedCard('7083050030080407500');
};

const closeModal = () => {
  setSelectedCard(null);
};

  const filteredData = useMemo(() => {
    let filtered = data;

    if (statusFilter) {
      filtered = filtered.filter(d => d.Status === statusFilter);
    }

    if (policyFilter) {
      const policyFilterInt = parseInt(policyFilter, 10);
      filtered = filtered.filter(d => parseInt(d.PolicyNumber, 10) === policyFilterInt);
    }

    if (searchQuery) {
      filtered = filtered.filter((row) => {
        return Object.values(row).some((s) =>
          s && s.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
    }

    return filtered;
  }, [statusFilter, policyFilter, searchQuery, data]);

  const syncDataWithEFS = async () => {
    setLoading(true);
    setAlertMessage(null);

    try {
      const response = await fetch('https://efs-getpolicy.azurewebsites.net/api/FuelLink-GetCardSummaries', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tenantId: currentUser.tenantId })
      });

      if (response.ok) {
        setAlertMessage('Successfully synced card data with EFS');
      } else {
        setAlertMessage(`Error during synchronization: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      setAlertMessage(`Error during synchronization: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(() => [
    {
      name: 'Card Number',
      selector: row => row.CardNumber,
      sortable: true,
      reorder: true,
      style: {
        width: '300px',
        whiteSpace: 'nowrap'
      }
    },
    {
      name: 'Driver Name',
      selector: row => row.DriverName || 'N/A',
      sortable: true,
      reorder: true,
      hide: 'sm',
    },
    {
      name: 'Driver ID',
      selector: row => row.DriverId || 'N/A',
      sortable: true,
      reorder: true,
      hide: 'sm'
    },
    {
      name: 'Unit ID',
      selector: row => row.UnitNumber,
      sortable: true,
      reorder: true,
      hide: 'sm'
    },
    {
      name: 'Policy Number',
      selector: row => row.PolicyNumber,
      sortable: true,
      reorder: true
    },
    {
      name: 'Status',
      selector: row => row.Status,
      sortable: true,
      reorder: true,
      hide: 'sm'
    },
    {
      name: 'Actions',
      cell: row => (
          <div className="flex space-x-3">
              <button
                  className="themed-button"
                  onClick={() => handleEditClick(row)}
              >
                  Edit
              </button>
              <button
                  className="themed-button"
                  onClick={() => handleViewRejects(row)}
              >
                  View Rejects
              </button>                    
          </div>
      ),
      ignoreRowClick: true,
      width: '250px'
  }
], [handleViewRejects, handleEditClick]);

  const filterConfig = [
    { key: 'CardNumber', type: 'string' },
    { key: 'DriverName', type: 'string' },
    { key: 'DriverId', type: 'string' },
    { key: 'UnitNumber', type: 'string' },
    { key: 'PolicyNumber', type: 'number' },
    { key: 'Status', type: 'string' }
  ];

  return (
    <div className="mobile-container mx-auto px-4 sm:px-6 lg:px-8">
      <MobileHeader theme={theme} />
      <PageHeader 
        heading="Admin Card Management" 
        context="Order New Cards & Update Existing Cards. All cards for all policies will appear here."
      />
      <div className="bg-gray-300 p-4 rounded-lg mb-6">
        <div className="grid md:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label className="text-sm font-medium text-gray-700 mb-1">
              Status:
            </label>
            <select 
              value={statusFilter} 
              onChange={e => setStatusFilter(e.target.value)} 
              className="bg-white rounded-md p-2"
            >
              <option value="">All</option>
              <option value="ACTIVE">ACTIVE</option>
              <option value="INACTIVE">INACTIVE</option>
              <option value="HOLD">HOLD</option>
              <option value="FRAUD">FRAUD</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="text-sm font-medium text-gray-700 mb-1">
              Policy:
            </label>
            <select 
              value={policyFilter} 
              onChange={e => setPolicyFilter(e.target.value)} 
              className="bg-white rounded-md p-2"
            >
              <option value="">All Policies</option>
              {policies.map((policy) => (
                <option key={policy.ID} value={policy.PolicyNumber}>
                  {policy.PolicyNumber} - {policy.Description}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <MainTableV2
        columns={columns}
        data={filteredData}
        primaryColor={primaryColor}
        loading={loading}
        alertMessage={alertMessage}
        clearAlert={clearAlert}        
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterConfig={filterConfig}
      >
        <button 
          className="themed-button" 
          onClick={syncDataWithEFS}
        >
          Sync Card Data With EFS
        </button>
      </MainTableV2>
      
      {selectedCard && (
                <TransactionRejectModal
                    cardNumber={selectedCard}
                    onClose={closeModal}
                />
            )}
    </div>
  );
};

export default CardSummaryTable;




// import React, { useState, useEffect, useMemo } from 'react'; 
// import { useTheme } from './ThemeContext';
// import { useAuth } from './AuthContext';
// import MainTable from './MainTable';  
// import { useDispatch, useSelector } from 'react-redux';
// import { setCardData, updateCardData } from './cardSlice';
// import { useNavigate } from 'react-router-dom';
// import PageHeader from './PageHeader';
// import MobileHeader from './MobileHeader';
// import MainTableV2 from './MainTableV2';

// const CardSummaryTable = () => {
//     const { theme } = useTheme();
//     const { currentUser } = useAuth();
//     const primaryColor = theme.primary;
//     const tenantId = theme.tenantId;
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [alertMessage, setAlertMessage] = useState(null);
//     const [searchQuery, setSearchQuery] = useState('');
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const clearAlert = () => setAlertMessage(null);
//     const [statusFilter, setStatusFilter] = useState('');
//     const [policyFilter, setPolicyFilter] = useState('');
//     const policies = useSelector((state) => state.policies.policies); 
//     useEffect(() => {
//       // console.log("Policies:", policies);
//     }, [policies]);    
       
//     const handleStatusChange = (e) => setStatusFilter(e.target.value);
//     const handlePolicyChange = (e) => setPolicyFilter(e.target.value);
//     const [originalData, setOriginalData] = useState([]);
//     useEffect(() => {
//       // console.log("Original Data: ", originalData);
//     }, [originalData]); 
    
    
//     useEffect(() => {
//         const fetchData = async () => {
//             setLoading(true);
//             try {
//                 const response = await fetch('https://efs-getpolicy.azurewebsites.net/api/FuelLink-LoadCardDataIntoTable?', {
//                     method: 'POST',
//                     headers: { 'Content-Type': 'application/json' },
//                     body: JSON.stringify({ userId: currentUser?.userId })
//                 });

//                 if (response.ok) {
//                     const fetchedData = await response.json();
//                     // console.log(fetchedData);
//                     setOriginalData(fetchedData);
//                     setData(fetchedData);                    
//                 } else {
//                     setAlertMessage(`Server Error: ${await response.text()}`);
//                 }
//             } catch (error) {
//                 setAlertMessage(`Error: ${error.message}`);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchData();
//     }, [tenantId]);

//     const handleEditClick = (cardData) => {         
//         dispatch(setCardData(cardData));        
//         navigate('/CardsAdminEdit'); 
//       };
    
      

//       const filteredData = useMemo(() => {
//         let filtered = originalData; 
      
//         // Apply status filter
//         if (statusFilter) {
//           filtered = filtered.filter(d => d.Status === statusFilter);
//         }
      
//         // Apply policy filter
//         if (policyFilter) {          
//           const policyFilterInt = parseInt(policyFilter, 10);
//           filtered = filtered.filter(d => parseInt(d.PolicyNumber, 10) === policyFilterInt);
//         }
      
//         // Apply search box filter
//         if (searchQuery) {
//           filtered = filtered.filter((row) => {
//             return Object.values(row).some((s) =>
//               s && s.toString().toLowerCase().includes(searchQuery.toLowerCase())
//             );
//           });
//         }
      
//         return filtered;
//       }, [statusFilter, policyFilter, searchQuery, originalData]);

//       const syncDataWithEFS = async () => {
//         setLoading(true);
//         setAlertMessage(null); // Clear any existing messages
      
//         const requestBody = {
//           tenantId: currentUser.tenantId
//         };
      
//         try {
//           const response = await fetch('https://efs-getpolicy.azurewebsites.net/api/FuelLink-GetCardSummaries', {
//             method: 'POST',
//             headers: {
//               'Content-Type': 'application/json',              
//             },
//             body: JSON.stringify(requestBody)
//           });
      
//           if (response.ok) {
//             const data = await response.text();            
//             setAlertMessage('Successfully synced card data with EFS');
//           } else {           
//             setAlertMessage(`Error during synchronization: ${response.status} ${response.statusText}`);
//           }
//         } catch (error) {          
//           setAlertMessage(`Error during synchronization: ${error.message}`);
//         } finally {
//           setLoading(false);
//         }
//       };
      
      
    

//     // const columns = useMemo(() => [
//     //     { Header: 'Card Number', accessor: 'CardNumber' },
//     //     { Header: 'Driver ID', accessor: 'DriverId' },
//     //     { Header: 'Driver Name', accessor: 'DriverName' },
//     //     { Header: 'Unit Number', accessor: 'UnitNumber' },
//     //     { Header: 'Policy Number', accessor: 'PolicyNumber' },
//     //     { Header: 'Status', accessor: 'Status' },
//     //     {
//     //         Header: 'Edit',
//     //         Cell: ({ row }) => (
//     //           <button
//     //             className="themed-button"
//     //             onClick={() => handleEditClick(row.original)} 
//     //           >
//     //             Edit
//     //           </button>
//     //         ),
//     //       }
          

//     // ], []);
//     const columns = useMemo(() => [
//       {
//         name: 'Card Number',
//         selector: row => row.CardNumber,
//         sortable: true,
//         reorder: true,  
//         style: {
//           width: '300px', // This can help ensure that the width is based on the content
//           whiteSpace: 'nowrap' // Ensures text does not wrap to the next line
//       }      
//     },
//     {
//         name: 'Driver Name',
//         selector: row => row.DriverName || 'N/A', // Handling potential null values
//         sortable: true,
//         reorder: true,
//         hide: 'sm',
//     },
//     {
//         name: 'Driver ID',
//         selector: row => row.DriverId || 'N/A',
//         sortable: true,
//         reorder: true,
//         hide: 'sm'
//     },
//     {
//         name: 'Unit ID',
//         selector: row => row.UnitNumber,
//         sortable: true,
//         reorder: true,
//         hide: 'sm'
//     },
//     {
//       name: 'Policy Number',
//       selector: row => row.PolicyNumber,
//       sortable: true,
//       reorder: true      
//   },
//     {
//         name: 'Status',
//         selector: row => row.Status,
//         sortable: true,
//         reorder: true,
//         hide: 'sm'
//     },
//       {
//           name: 'Edit',
//           cell: row => (
//               <button
//                   className="themed-button"
//                   onClick={() => handleEditClick(row)}
//               >
//                   Edit
//               </button>
//           ),
//           ignoreRowClick: true,          
//       }
//   ], [handleEditClick]);

//   const filterConfig = [
//     { key: 'CardNumber', type: 'string' },
//     { key: 'DriverName', type: 'string' }, 
//     { key: 'DriverId', type: 'string' }, 
//     { key: 'UnitNumber', type: 'string' }, 
//     { key: 'PolicyNumber', type: 'number' },
//     { key: 'Status', type: 'string' }
// ];

//     return (
//       <div className="mobile-container mx-auto px-4 sm:px-6 lg:px-8">
//       <MobileHeader theme={theme} />
//           <PageHeader 
//             heading="Admin Card Management" 
//             context="Order New Cards & Update Existing Cards. All cards for all policies will appear here."
//           />
//           <div className="bg-gray-300 p-4 rounded-lg mb-6">
//   <div className="grid md:grid-cols-2 gap-4">
//     {/* For Status */}
//     <div className="flex flex-col">
//       <label className="text-sm font-medium text-gray-700 mb-1">        
//         Status:
//       </label>
//       <select 
//         value={statusFilter} 
//         onChange={handleStatusChange} 
//         className="bg-white rounded-md p-2"
//       >
//         <option value="">All</option>
//         <option value="ACTIVE">ACTIVE</option>
//         <option value="INACTIVE">INACTIVE</option>
//         <option value="HOLD">HOLD</option>
//         <option value="FRAUD">FRAUD</option>
//       </select>
//     </div>
//     {/* For Policy */}
//     <div className="flex flex-col">
//       <label className="text-sm font-medium text-gray-700 mb-1">        
//         Policy:
//       </label>
//       <select 
//         value={policyFilter} 
//         onChange={handlePolicyChange} 
//         className="bg-white rounded-md p-2"
//       >
//         <option value="">All Policies</option>
//         {policies && policies.map((policy) => (
//           <option key={policy.ID} value={policy.PolicyNumber}>
//             {policy.PolicyNumber} - {policy.Description}
//           </option>
//         ))}
//       </select>
//     </div>
//   </div>
// </div>



//           {/* <MainTable
//             columns={columns}
//             data={filteredData}
//             primaryColor={primaryColor}
//             loading={loading}
//             alertMessage={alertMessage}
//             clearAlert={clearAlert}
//             title="Cards"            
//             searchQuery={searchQuery}
//             setSearchQuery={setSearchQuery}
//           >
//             <button 
//               className="themed-button" 
//               onClick={() => syncDataWithEFS()}
//             >
//               Sync Card Data With EFS
//             </button>
//           </MainTable> */}
//           <MainTableV2
//             columns={columns}
//             data={filteredData}
//             primaryColor={primaryColor}
//             loading={loading}
//             alertMessage={alertMessage}
//             clearAlert={clearAlert}
//             title="Cards"            
//             searchQuery={searchQuery}
//             setSearchQuery={setSearchQuery}
//             filterConfig={filterConfig}
//         >
//           <button 
//               className="themed-button" 
//               onClick={() => syncDataWithEFS()}
//             >
//               Sync Card Data With EFS
//             </button>
//           </MainTableV2> 
//         </div>
//       );
      
// };

// export default CardSummaryTable;
