import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from './ThemeContext';

const Modal = ({ isOpen, onClose, title, children }) => {
    const { theme } = useTheme();
    const primaryColor = theme.primary;
    const primaryTextColor = theme.BtnTxtPrimaryColor;

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal fixed inset-0 z-50 flex items-center justify-center">
            <div className="modal-overlay absolute inset-0 bg-black opacity-50" onClick={onClose}></div>

            <div className="relative bg-white w-[95%] h-[75%] sm:w-11/12 sm:h-[65%] lg:w-3/4 lg:h-[75%] rounded flex flex-col">
                {/* Header */}
                <div 
                    className="modal-header flex items-center justify-between px-6 py-4 rounded-t"
                    style={{ backgroundColor: primaryColor, color: primaryTextColor }}
                >
                    {title && <h2 className="text-lg font-semibold">{title}</h2>}
                    <button
                        className="text-white hover:text-gray-300 focus:outline-none"
                        onClick={onClose}
                    >
                        &#10005;
                    </button>
                </div>

                {/* Body */}
                <div className="modal-body flex-grow overflow-y-auto px-6 py-4">
                    {children}
                </div>

                {/* Footer */}
                <div className="modal-footer px-6 py-4 border-t flex justify-end bg-gray-100 rounded-b">
                    <button
                        className="themed-button h-10 px-6"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default Modal;
